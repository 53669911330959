import { memo, useCallback, useMemo } from 'react';
import { cn } from '../../../../helpers/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { moveNode } from '../../../../redux/slices/diagram';
import { MoveableNodeTypes, VerticallyMoveableNodeType } from '../../../util/node-util';
import ArrowDownIcon from '../icons/arrow-down-icon.component';
import ArrowUpIcon from '../icons/arrow-up-icon.component';

export enum VerticalMoveDirection {
  UP,
  DOWN,
}

interface VerticalMoveControlProps {
  id: string;
  type: VerticallyMoveableNodeType;
  index: number;
  title: string;
  direction: VerticalMoveDirection;
  disabled: boolean;
}

const VerticalMoveControl = ({ id, type, index, direction, title, disabled }: VerticalMoveControlProps) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      dispatch(
        moveNode({
          id,
          type: type as MoveableNodeTypes,
          currentIndex: index,
          targetIndex: direction === VerticalMoveDirection.UP ? index - 1 : index + 1,
        })
      );
    },
    [index, direction, disabled]
  );

  const icon = useMemo(
    () => (direction === VerticalMoveDirection.UP ? <ArrowUpIcon /> : <ArrowDownIcon />),
    [direction]
  );

  return (
    <div
      title={title}
      className={cn(
        'nopan nodrag bt-invisible bt-absolute bt-left-[calc(50%-12px)] bt-cursor-pointer group-hover:bt-visible',
        { '-bt-top-6': direction === VerticalMoveDirection.UP },
        { '-bt-bottom-6': direction === VerticalMoveDirection.DOWN },
        { 'bt-hidden': disabled }
      )}
      onClick={handleClick}
    >
      {icon}
    </div>
  );
};

export default memo(VerticalMoveControl);
