import { LoginCallback, LogoutCallback } from '@myosh/myosh-login';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppPermissionsProvider } from '../../context/app-permissions.context';
import { ConfigurationProvider } from '../../context/configuration.context';
import { UserPermissionsProvider } from '../../context/user-permissions.context';
import DiagramContextWrapper from '../../flow/diagram.context.wrapper';
import RestrictedDiagramRoute from '../../flow/restricted-diagram-route';
import MainFlow from '../../flow/views/main';
import Configuration from '../../views/configuration';
import Main from '../../views/main';
import LoginPage from './login-page';
import NoMatchRoute from './no-match-route';
import PrivateRoute from './private-route';
import SuperuserRoute from './superuser-route';

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/auth/cb/logout" element={<LogoutCallback />} />
      <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
      <Route path="/auth/cb" element={<LoginCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route
          index
          element={
            <ConfigurationProvider>
              <UserPermissionsProvider>
                <DiagramContextWrapper>
                  <AppPermissionsProvider>
                    <MainFlow />
                  </AppPermissionsProvider>
                </DiagramContextWrapper>
              </UserPermissionsProvider>
            </ConfigurationProvider>
          }
        />
        <Route path="legacy" element={<RestrictedDiagramRoute />}>
          <Route
            index
            element={
              <ConfigurationProvider>
                <UserPermissionsProvider>
                  <Main />
                </UserPermissionsProvider>
              </ConfigurationProvider>
            }
          />
        </Route>
        <Route path="configuration" element={<SuperuserRoute />}>
          <Route index element={<Configuration />} />
        </Route>
      </Route>
      <Route path="*" element={<NoMatchRoute />} />
    </Routes>
  );
}

export default React.memo(RoutesComponent);
