import { useRef } from 'react';
import { useGetSimpleOptionsForFieldQuery } from '../../api/enhanced/enhanced-v4-api';
import { useConfigurationContext } from '../../context/configuration.context';

type ValueIdResolver = (value: string) => number | undefined;
export type ValueResolvers = { [key: string]: ValueIdResolver };

/**
 * Custom hook that manages value resolvers for control-related form fields.
 *
 * @remarks
 * This hook fetches options for various control fields and creates resolver functions
 * that can map caption values to their corresponding IDs.
 *
 * @returns An object containing:
 * - `valueResolvers` - Object containing resolver functions for different control fields:
 *   - `criticalRankIdResolver` - Resolves critical/non-critical rank captions to IDs
 *   - `categoryIdResolver` - Resolves category (hard/soft) captions to IDs
 *   - `controlTypeResolver` - Resolves control type captions to IDs
 *   - `initialImplementationRequiredIdResolver` - Resolves implementation requirement captions to IDs
 *   - `materialRiskRelationIdResolver` - Resolves material risk relation captions to IDs
 * - `isFetching` - Boolean indicating if any of the main options are being fetched
 * - `isMaterialRiskRelationFieldOptionsFetching` - Boolean indicating if material risk relation options are being fetched
 *
 * @example
 * ```typescript
 * const { valueResolvers, isFetching } = useControlValueResolvers();
 * const id = valueResolvers.categoryIdResolver("Hard");
 * ```
 */
const useControlValueResolvers = () => {
  const criticalRankIdResolver = useRef<ValueIdResolver>(); // critical / non critical
  const categoryIdResolver = useRef<ValueIdResolver>(); // hard / soft
  const controlTypeResolver = useRef<ValueIdResolver>(); // control sub type
  const initialImplementationRequiredIdResolver = useRef<ValueIdResolver>(); // initial implementation required flag
  const materialRiskRelationIdResolver = useRef<ValueIdResolver>();

  const {
    controls: {
      formId,
      criticalOrNonCriticalFieldId,
      categoryFieldId,
      controlTypeFieldId,
      initialImplementationRequiredFieldId,
      materialRiskRelationFieldId,
    },
  } = useConfigurationContext();

  const { data: criticalNonCriticalOptions, isFetching: isCriticalNonCriticalOptionsFetching } =
    useGetSimpleOptionsForFieldQuery(
      {
        fieldId: criticalOrNonCriticalFieldId,
        formId: formId,
        limit: -1,
      },
      { skip: !formId || !criticalOrNonCriticalFieldId }
    );

  const { data: categoryOptions, isFetching: isCategoryOptionsFetching } = useGetSimpleOptionsForFieldQuery(
    {
      fieldId: categoryFieldId,
      formId: formId,
      limit: -1,
    },
    { skip: !formId || !categoryFieldId }
  );

  const { data: controlTypes, isFetching: isControlTypesFetching } = useGetSimpleOptionsForFieldQuery(
    {
      fieldId: controlTypeFieldId,
      formId: formId,
      limit: -1,
    },
    { skip: !formId || !controlTypeFieldId }
  );

  const { data: initialImplementationRequiredOptions, isFetching: isInitialImplementationRequiredOptionsFetching } =
    useGetSimpleOptionsForFieldQuery(
      {
        fieldId: initialImplementationRequiredFieldId,
        formId: formId,
        limit: -1,
      },
      { skip: !formId || !initialImplementationRequiredFieldId }
    );

  const { data: materialRiskRelationFieldOptions, isFetching: isMaterialRiskRelationFieldOptionsFetching } =
    useGetSimpleOptionsForFieldQuery(
      {
        fieldId: materialRiskRelationFieldId,
        formId: formId,
        limit: -1,
      },
      { skip: !formId || !materialRiskRelationFieldId }
    );

  if (!criticalRankIdResolver.current && !isCriticalNonCriticalOptionsFetching && criticalNonCriticalOptions) {
    criticalRankIdResolver.current = (value: string) => {
      return criticalNonCriticalOptions?.find((option) => option.caption === value)?.id;
    };
  }

  if (!categoryIdResolver.current && !isCategoryOptionsFetching && categoryOptions) {
    categoryIdResolver.current = (value: string) => {
      return categoryOptions?.find((option) => option.caption === value)?.id;
    };
  }

  if (!controlTypeResolver.current && !isControlTypesFetching && controlTypes) {
    controlTypeResolver.current = (value: string) => {
      return controlTypes?.find((option) => option.caption === value)?.id;
    };
  }

  if (
    !initialImplementationRequiredIdResolver.current &&
    !isInitialImplementationRequiredOptionsFetching &&
    initialImplementationRequiredOptions
  ) {
    initialImplementationRequiredIdResolver.current = (value: string) => {
      return initialImplementationRequiredOptions?.find((option) => option.caption === value)?.id;
    };
  }

  if (
    !materialRiskRelationIdResolver.current &&
    !isMaterialRiskRelationFieldOptionsFetching &&
    materialRiskRelationFieldOptions
  ) {
    materialRiskRelationIdResolver.current = (value: string) => {
      return materialRiskRelationFieldOptions?.find((option) => option.caption === value)?.id;
    };
  }

  return {
    valueResolvers: {
      criticalRankIdResolver: criticalRankIdResolver.current!,
      categoryIdResolver: categoryIdResolver.current!,
      controlTypeResolver: controlTypeResolver.current!,
      initialImplementationRequiredIdResolver: initialImplementationRequiredIdResolver.current!,
      materialRiskRelationIdResolver: materialRiskRelationIdResolver.current!,
    },
    isFetching:
      isCriticalNonCriticalOptionsFetching ||
      isCategoryOptionsFetching ||
      isControlTypesFetching ||
      isInitialImplementationRequiredOptionsFetching ||
      isMaterialRiskRelationFieldOptionsFetching,
  };
};
export default useControlValueResolvers;
