/* eslint-disable @typescript-eslint/no-unused-vars */
import { addListener, createListenerMiddleware, isAnyOf, ListenerEffectAPI } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { ActionCreators } from 'redux-undo';
import { enhancedV4Api } from '../api/enhanced/enhanced-v4-api';
import { ControlDiagramNode } from '../flow/@types/diagram';
import { isCauseOrConsequenceNode, isControlNode, isLeafNodeType } from '../flow/util/node-util';
import { BowtieConfiguration } from '../services/common-data-types';
import {
  removeNode,
  setDiagramDisabled,
  updateNode,
  UpdateNodeAction,
  UpdateNodeActionData,
  updateNodeDataFromListener,
} from './slices/diagram';
import { AppDispatch, RootState } from './store';

/**
 * Redux listener middleware instance for handling action-based side effects.
 * Created using Redux Toolkit's `createListenerMiddleware()`.
 *
 * @type {ListenerMiddleware}
 * @see {@link https://redux-toolkit.js.org/api/createListenerMiddleware}
 */
export const listenerMiddleware = createListenerMiddleware();

/**
 * A strongly-typed `createListenerMiddleware().addListener` function for the app.
 * This middleware enables adding typed listener functions that can respond to Redux actions.
 *
 * @typeparam RootState - The type of the root state in the Redux store
 * @typeparam AppDispatch - The type of the dispatch function
 *
 * @see {@link https://redux-toolkit.js.org/api/createListenerMiddleware}
 */
export const addAppListener = addListener.withTypes<RootState, AppDispatch>();

/**
 * Typed version of Redux Toolkit's `startListening` middleware function.
 * Used to set up listener middleware with correct type inference for the application's
 * root state and dispatch types.
 *
 * @see {@link https://redux-toolkit.js.org/api/createListenerMiddleware#startlisteningwithtype}
 *
 * @type {Function}
 * @typeParam RootState - The type of the root state
 * @typeParam AppDispatch - The type of the app dispatch function
 */
const startAppListening = listenerMiddleware.startListening.withTypes<RootState, AppDispatch>();

// Start listening for diagram actions
startAppListening({
  matcher: isAnyOf(updateNode, removeNode),
  effect: async (action, listenerApi) => {
    const appState = listenerApi.getState();
    const {
      flowDiagram: { present: diagramState },
    } = appState;

    // Sync with the backend when there is a risk scenario record
    if (diagramState.mue.recordId) {
      // update node action (handles label changes, new record creation and linking with existing record controls)
      if (updateNode.match(action)) {
        const { bowtieConfiguration, context } = await _getActionContext(listenerApi, action.payload.type);

        const { type, data } = action.payload;

        if (data.control && isControlNode(type)) {
          // existing control node (record), the appropriate diagram records need to be linked with this record
          _linkExistingRecord(listenerApi, data, context, bowtieConfiguration.main.formId!, diagramState.mue.recordId);
        } else if (!data.control && data.recordId) {
          // existing node (record), the label updated needs to be synced with the backend
          _updateRecordLabel(listenerApi, data, context);
        } else if (!data.control && !data.recordId && isLeafNodeType(type) && context.form) {
          // new node, a record needs to be created and linked with the appropriate records in the diagram
          _createAndLinkRecord(
            listenerApi,
            action.payload,
            context,
            bowtieConfiguration.main.formId!,
            diagramState.mue.recordId
          );
        }
      }

      if (removeNode.match(action) && action.payload.data.recordId) {
        const { bowtieConfiguration, context } = await _getActionContext(listenerApi, action.payload.type);
        const { data } = action.payload;

        // remove controls action
        if (isControlNode(action.payload.type)) {
          _unlinkControl(listenerApi, data, context, bowtieConfiguration.main.formId!, diagramState.mue.recordId);
        }

        // remove cause/consequence action
        if (isCauseOrConsequenceNode(action.payload.type)) {
          _unlinkCauseOrConsequence(listenerApi, data, context, diagramState.mue.recordId);
        }
      }
    }
  },
});

// Utility functions
type ActionContextType = { bowtieConfiguration: BowtieConfiguration; context: NodeContextType };

const _getActionContext = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  payloadType: string
): Promise<ActionContextType> => {
  // fetch bowtie configuration
  const bowtieConfiguration = await listenerApi.dispatch(enhancedV4Api.endpoints.appConfiguration.initiate()).unwrap();

  // reslove node context
  const context = _getNodeContext(payloadType, bowtieConfiguration);

  return { bowtieConfiguration, context };
};

type NodeContextType = ReturnType<typeof _getNodeContext>;

const _getNodeContext = (type: string, bowtieConfiguration: BowtieConfiguration) => {
  let formId,
    fieldKey,
    form,
    mueRecordLinkFieldId,
    causeConsequenceMainRecordLinkField,
    causeOrConsequenceRecordLinkField,
    controlTypeKey,
    controlTypeValue;

  if (type === 'hazard-node') {
    formId = bowtieConfiguration.main.formId;
    fieldKey = bowtieConfiguration.main.hazardFieldId;
    form = bowtieConfiguration.main.form;
  } else if (type === 'mue-node') {
    formId = bowtieConfiguration.main.formId;
    fieldKey = bowtieConfiguration.main.scenarioFieldId;
    form = bowtieConfiguration.main.form;
  } else if (type === 'cause-node') {
    formId = bowtieConfiguration.causes.formId;
    fieldKey = bowtieConfiguration.causes.fieldId;
    form = bowtieConfiguration.causes.form;

    mueRecordLinkFieldId = bowtieConfiguration.main.causesReverseRecordLinkFieldId;
    causeConsequenceMainRecordLinkField = bowtieConfiguration.causes.mainFormRecordLinkFieldId;
  } else if (type === 'consequence-node') {
    formId = bowtieConfiguration.consequences.formId;
    fieldKey = bowtieConfiguration.consequences.fieldId;
    form = bowtieConfiguration.consequences.form;

    mueRecordLinkFieldId = bowtieConfiguration.main.consequencesReverseRecordLinkFieldId;
    causeConsequenceMainRecordLinkField = bowtieConfiguration.consequences.mainFormRecordLinkFieldId;
  } else if (type === 'mitigating-control-node') {
    formId = bowtieConfiguration.controls.formId;
    fieldKey = bowtieConfiguration.controls.fieldId;
    form = bowtieConfiguration.controls.form;

    mueRecordLinkFieldId = bowtieConfiguration.main.mitigatingControlsRecordLinkFieldId;
    causeOrConsequenceRecordLinkField = bowtieConfiguration.controls.mitigatingControls.consequencesRecordLinkFieldId;

    controlTypeKey = bowtieConfiguration.controls.typeFieldId; // 'Preventative/Mitigating';
    // TODO: value should be resolved from the config
    controlTypeValue = { value: 'Mitigating' };
  } else if (type === 'preventative-control-node') {
    formId = bowtieConfiguration.controls.formId;
    fieldKey = bowtieConfiguration.controls.fieldId;
    form = bowtieConfiguration.controls.form;

    mueRecordLinkFieldId = bowtieConfiguration.main.preventativeControlsRecordLinkFieldId;
    causeOrConsequenceRecordLinkField = bowtieConfiguration.controls.preventativeControls.causesRecordLinkFieldId;

    controlTypeKey = bowtieConfiguration.controls.typeFieldId; // 'Preventative/Mitigating';
    // TODO: value should be resolved from the config
    controlTypeValue = { value: 'Preventative' };
  }

  return {
    formId,
    fieldKey,
    form,
    mueRecordLinkFieldId,
    causeConsequenceMainRecordLinkField,
    causeOrConsequenceRecordLinkField,
    controlTypeKey,
    controlTypeValue,
  };
};

const _linkExistingRecord = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  data: UpdateNodeActionData,
  context: NodeContextType,
  mueFormId: number,
  mueRecordId: number
) => {
  const { causeOrConsequenceRecordLinkField, mueRecordLinkFieldId } = context;
  // existing control node (record), the appropriate diagram records need to be linked with this record
  const toastId = toast.loading(`Linking "${data.label}", please wait...`);

  try {
    listenerApi.dispatch(setDiagramDisabled(true));

    const fields: { [key: string]: unknown } = {};

    if (data.parentRecordId && causeOrConsequenceRecordLinkField) {
      // update the linked record list on the cause/consequence record
      fields[causeOrConsequenceRecordLinkField] = [
        ...((data.control!.fields?.[causeOrConsequenceRecordLinkField] ?? []) as string[]),
        String(data.parentRecordId),
      ];
    }

    await listenerApi
      .dispatch(
        enhancedV4Api.endpoints.patchSimple.initiate({
          id: data.control!.id!,
          params: {},
          simpleRecordDto: {
            formId: data.control!.formId,
            fields,
          },
        })
      )
      .unwrap();

    // fetch the mue record and append the recordId to the correct linked record list
    await _updateMueRecord(listenerApi, mueFormId, mueRecordId, mueRecordLinkFieldId!, data.control!.id!);

    toast.update(toastId, {
      render: `"${data.label}" successfully linked.`,
      type: 'success',
      isLoading: false,
      autoClose: 3000,
    });
  } catch (_) {
    listenerApi.dispatch(ActionCreators.undo());
    toast.update(toastId, {
      render: 'Unable to link record, please try again.',
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });
  } finally {
    listenerApi.dispatch(setDiagramDisabled(false));
  }
};

const _updateRecordLabel = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  data: UpdateNodeActionData,
  context: NodeContextType
) => {
  try {
    const { formId, fieldKey } = context;

    listenerApi.dispatch(setDiagramDisabled(true));

    await listenerApi
      .dispatch(
        enhancedV4Api.endpoints.patchSimple.initiate({
          id: data.recordId!,
          params: {},
          simpleRecordDto: {
            formId,
            fields: {
              [fieldKey!]: data.label,
            },
          },
        })
      )
      .unwrap();
  } catch (_) {
    listenerApi.dispatch(ActionCreators.undo());
    toast.error('Unable to update record, please try again.', { autoClose: 3000 });
  } finally {
    listenerApi.dispatch(setDiagramDisabled(false));
  }
};

const _createAndLinkRecord = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  actionPayload: UpdateNodeAction,
  context: NodeContextType,
  mueFormId: number,
  mueRecordId: number
) => {
  const { id, type, data, rowIndex } = actionPayload;

  const {
    fieldKey,
    form,
    causeConsequenceMainRecordLinkField,
    causeOrConsequenceRecordLinkField,
    mueRecordLinkFieldId,
    controlTypeKey,
    controlTypeValue,
  } = context;

  const status = form!.workflowSteps.find((step) => step.draft === true)?.label ?? form!.workflowSteps[0].label;

  const toastId = toast.loading(`Creating "${data.label}", please wait...`);

  try {
    listenerApi.dispatch(setDiagramDisabled(true));

    const fields: { [key: string]: unknown } = {
      [fieldKey!]: data.label,
    };

    if (causeConsequenceMainRecordLinkField) {
      fields[causeConsequenceMainRecordLinkField] = [String(mueRecordId)];
    }

    if (data.parentRecordId && causeOrConsequenceRecordLinkField && controlTypeKey && controlTypeValue) {
      // when it's a control node, update the linked record list on the cause/consequence record
      fields[causeOrConsequenceRecordLinkField] = [String(data.parentRecordId)];
      fields[controlTypeKey] = controlTypeValue;
    }

    const response = await listenerApi
      .dispatch(
        enhancedV4Api.endpoints.createSimpleRecords.initiate({
          params: {},
          body: [
            {
              formId: form!.id,
              status,
              fields,
            },
          ],
        })
      )
      .unwrap();

    if (response.length === 1 && response[0].success === true) {
      const recordId = response[0].id;

      // fetch the mue record and append the new recordId to the correct linked record list
      await _updateMueRecord(listenerApi, mueFormId, mueRecordId, mueRecordLinkFieldId!, recordId!);

      // fetch the newly created record
      const record = await listenerApi
        .dispatch(enhancedV4Api.endpoints.getSimpleRecord.initiate({ id: recordId!, params: {} }))
        .unwrap();

      // update the flow diagram with the new node data recordId/linkUrl
      listenerApi.dispatch(
        updateNodeDataFromListener({
          id,
          type,
          data: {
            ...data,
            recordId,
            linkUrl: record?.linkUrl,
          },
          rowIndex,
        })
      );

      toast.update(toastId, {
        render: `"${data.label}" successfully created.`,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      listenerApi.dispatch(ActionCreators.undo());
      toast.update(toastId, {
        render: 'Unable to create record, please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  } catch (_) {
    listenerApi.dispatch(ActionCreators.undo());
    toast.update(toastId, {
      render: 'Unable to create record, please try again.',
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });
  } finally {
    listenerApi.dispatch(setDiagramDisabled(false));
  }
};

const _unlinkControl = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  data: ControlDiagramNode,
  context: NodeContextType,
  mueFormId: number,
  mueRecordId: number
) => {
  const toastId = toast.loading(`Unlinking "${data.label}", please wait...`);

  const { formId, causeOrConsequenceRecordLinkField, mueRecordLinkFieldId } = context;

  try {
    listenerApi.dispatch(setDiagramDisabled(true));

    if (data.parentRecordId && causeOrConsequenceRecordLinkField) {
      // fetch the mue record and remove the recordId from the list
      await _updateMueRecord(listenerApi, mueFormId, mueRecordId, mueRecordLinkFieldId!, data.recordId!, 'remove');

      // fetch record and unlink from cause/consequence
      const record = await listenerApi
        .dispatch(
          enhancedV4Api.endpoints.getSimpleRecord.initiate({ id: data.recordId!, params: {} }, { forceRefetch: true })
        )
        .unwrap();

      const causeOrConsequenceRecordLinkFieldValue = cloneDeep(
        (record?.fields?.[causeOrConsequenceRecordLinkField] as string[]) ?? []
      );

      const index = causeOrConsequenceRecordLinkFieldValue.findIndex((id) => id === String(data.parentRecordId));

      if (index > -1) {
        causeOrConsequenceRecordLinkFieldValue.splice(index, 1);
      }

      await listenerApi
        .dispatch(
          enhancedV4Api.endpoints.patchSimple.initiate({
            id: data.recordId!,
            params: {},
            simpleRecordDto: {
              formId,
              fields: { [causeOrConsequenceRecordLinkField]: causeOrConsequenceRecordLinkFieldValue },
            },
          })
        )
        .unwrap();

      toast.update(toastId, {
        render: `Control "${data.label}" successfully unlinked.`,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      listenerApi.dispatch(ActionCreators.undo());
      toast.update(toastId, {
        render: 'Unable to unlink control, please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  } catch (_) {
    listenerApi.dispatch(ActionCreators.undo());
    toast.update(toastId, {
      render: 'Unable to unlink control, please try again.',
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });
  } finally {
    listenerApi.dispatch(setDiagramDisabled(false));
  }
};

const _unlinkCauseOrConsequence = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  data: ControlDiagramNode,
  context: NodeContextType,
  mueRecordId: number
) => {
  const toastId = toast.loading(`Unlinking "${data.label}", please wait...`);

  const { causeConsequenceMainRecordLinkField, formId } = context;

  try {
    listenerApi.dispatch(setDiagramDisabled(true));

    if (data.recordId && causeConsequenceMainRecordLinkField) {
      const record = await listenerApi
        .dispatch(
          enhancedV4Api.endpoints.getSimpleRecord.initiate({ id: data.recordId!, params: {} }, { forceRefetch: true })
        )
        .unwrap();

      const causeConsequenceMainRecordLinkFieldValue = cloneDeep(
        (record?.fields?.[causeConsequenceMainRecordLinkField] as string[]) ?? []
      );

      const index = causeConsequenceMainRecordLinkFieldValue.findIndex((id) => id === String(mueRecordId));

      if (index > -1) {
        causeConsequenceMainRecordLinkFieldValue.splice(index, 1);
      }

      await listenerApi.dispatch(
        enhancedV4Api.endpoints.patchSimple.initiate({
          id: data.recordId,
          params: {},
          simpleRecordDto: {
            formId,
            fields: { [causeConsequenceMainRecordLinkField]: causeConsequenceMainRecordLinkFieldValue },
          },
        })
      );

      toast.update(toastId, {
        render: `"${data.label}" is successfully unlinked.`,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      listenerApi.dispatch(ActionCreators.undo());
      toast.update(toastId, {
        render: `Unable to unlink "${data.label}", please try again.`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  } catch (_) {
    listenerApi.dispatch(ActionCreators.undo());
    toast.update(toastId, {
      render: `Unable to unlink "${data.label}", please try again.`,
      type: 'error',
      isLoading: false,
      autoClose: 3000,
    });
  } finally {
    listenerApi.dispatch(setDiagramDisabled(false));
  }
};

// update the MUE record
const _updateMueRecord = async (
  listenerApi: ListenerEffectAPI<RootState, AppDispatch>,
  mueFormId: number,
  mueRecordId: number,
  mueRecordLinkFieldId: number,
  recordId: number,
  action: 'add' | 'remove' = 'add'
) => {
  // fetch the mue record and add/remove the recordId to the given linked record list field
  const mueRecord = await listenerApi
    .dispatch(enhancedV4Api.endpoints.getSimpleRecord.initiate({ id: mueRecordId, params: {} }, { forceRefetch: true }))
    .unwrap();

  const mueRecordLinkFieldValue = cloneDeep((mueRecord?.fields?.[mueRecordLinkFieldId] as string[]) ?? []);

  let fieldValueModified = false;
  if (action === 'add') {
    const index = mueRecordLinkFieldValue.findIndex((id) => String(id) === String(recordId));
    if (index === -1) {
      mueRecordLinkFieldValue.push(String(recordId));
      fieldValueModified = true;
    }
  } else if (action === 'remove') {
    const index = mueRecordLinkFieldValue.findIndex((id) => String(id) === String(recordId));
    if (index > -1) {
      fieldValueModified = true;
      mueRecordLinkFieldValue.splice(index, 1);
    }
  }

  if (fieldValueModified) {
    await listenerApi
      .dispatch(
        enhancedV4Api.endpoints.patchSimple.initiate({
          id: mueRecordId,
          params: {},
          simpleRecordDto: {
            formId: mueFormId,
            fields: { [mueRecordLinkFieldId]: mueRecordLinkFieldValue },
          },
        })
      )
      .unwrap();
  }
};
