import React, { memo } from 'react';
import { DiagramContextProvider } from '../context/diagram.context';
import useMainHooks from './hooks/use-main-hooks';

/**
 * A wrapper component for DiagramContextProvider that resolves context values.
 * Uses hooks to determine diagram state (AI-generated, new, or saved).
 *
 * @param {React.PropsWithChildren} props - React component props with children
 *
 * @returns {JSX.Element} The DiagramContextProvider with resolved context values
 *
 * @remarks Once we remove the old diagrams, these can be done inside the provider itself.
 */
const DiagramContextProviderWrapper = ({ children }: React.PropsWithChildren) => {
  const { isAIGenerated, isNewDiagram, isSavedDiagram } = useMainHooks();

  return (
    <DiagramContextProvider isAIGenerated={isAIGenerated} isNewDiagram={isNewDiagram} isSavedDiagram={isSavedDiagram}>
      {children}
    </DiagramContextProvider>
  );
};

export default memo(DiagramContextProviderWrapper);
