import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetBowtieByIdQuery } from '../../api/enhanced/enhanced-bowtie-api';
import { Bowtie, Control, ControlWithId } from '../../api/generated/bowtie-api';
import { ControlDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { CriticalControlEnum } from '../util/node-util';

const useSavedDiagram = () => {
  const diagramDataRef = useRef<DiagramConfiguration>();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const isSavedDiagram = queryParams.has('diagramId');

  const { data, isFetching } = useGetBowtieByIdQuery(
    { bowtieId: queryParams.get('diagramId')! },
    { skip: !isSavedDiagram }
  );

  if (isSavedDiagram && data && !isFetching && !diagramDataRef.current) {
    diagramDataRef.current = buildDiagramData(data);
  }

  return {
    isFetching,
    diagramData: diagramDataRef.current,
  };
};

export default useSavedDiagram;

/**
 * Builds a diagram configuration from a Bowtie data structure.
 *
 * This function transforms a Bowtie risk assessment data structure into a diagram
 * configuration that can be used to render a bow-tie diagram. It generates unique IDs
 * for each node and maps the data maintaining the bow-tie structure with causes,
 * consequences, and their respective controls.
 *
 * @param data - The Bowtie data structure containing risk assessment information
 * @returns A DiagramConfiguration object containing the structured diagram data
 *
 * @example
 * const bowtieData = {
 *   risk: "System Failure",
 *   hazard: "Equipment Malfunction",
 *   causes: [...],
 *   consequences: [...]
 * };
 * const diagramConfig = buildDiagramData(bowtieData);
 *
 * @remarks
 * - Generates unique IDs using crypto.randomUUID()
 * - Maps controls maintaining critical/non-critical status
 * - Preserves global control indicators
 * - Includes metadata for AI-generated controls
 */
function buildDiagramData(data: Bowtie): DiagramConfiguration {
  const diagramData: DiagramConfiguration = {
    mue: { id: crypto.randomUUID() },
    causes: [],
    consequences: [],
  };

  //mue
  diagramData.mue = {
    ...diagramData.mue,
    label: data.risk,
  };

  // hazard
  diagramData.hazard = {
    id: crypto.randomUUID(),
    label: data.hazard,
  };

  // causes and preventative controls
  diagramData.causes =
    data.causes?.map((cause) => {
      const controls =
        cause.controls?.map((control) => {
          const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
          const id = crypto.randomUUID();
          return {
            id,
            label: control.name,
            global: control.isGlobal,
            criticalControlType: criticalOrNonCritical,
            _metadata: {
              aiData: mapSavedDiagramControlToAIGeneratedControlWithId(id, control),
            },
          } as ControlDiagramNode;
        }) ?? [];

      return {
        id: crypto.randomUUID(),
        label: cause.name,
        controls,
      };
    }) ?? [];

  // consequences and mitigating controls
  diagramData.consequences =
    data.consequences?.map((consequence) => {
      const controls =
        consequence.controls?.map((control) => {
          const criticalOrNonCritical = control.isCritical ? CriticalControlEnum.CRITICAL : undefined;
          const id = crypto.randomUUID();
          return {
            id,
            label: control.name,
            global: control.isGlobal,
            criticalControlType: criticalOrNonCritical,
            _metadata: {
              aiData: mapSavedDiagramControlToAIGeneratedControlWithId(id, control),
            },
          } as ControlDiagramNode;
        }) ?? [];

      return {
        id: crypto.randomUUID(),
        label: consequence.name,
        controls,
      };
    }) ?? [];

  return diagramData;
}

const mapSavedDiagramControlToAIGeneratedControlWithId = (id: string, control: Control): ControlWithId => {
  return {
    id,
    name: control.name!,
    category: control.category!,
    type: control.type!,
    controlType: control.controlType!,
    isGlobal: Boolean(control.isGlobal),
    isCritical: Boolean(control.isCritical),
    isInitial: Boolean(control.isInitial),
    isControlIssues: false, // missing in the Bowtie service api
  };
};
