import { memo, useCallback, useMemo } from 'react';
import { cn } from '../../../../helpers/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { moveNode } from '../../../../redux/slices/diagram';
import { HorizontallyMoveableNodeType, MoveableNodeTypes } from '../../../util/node-util';
import ArrowLeftIcon from '../icons/arrow-left-icon.component';
import ArrowRightIcon from '../icons/arrow-right-icon.component';

export const HORIZONTAL_STEP = 2;

export enum HorizontalMoveDirection {
  LEFT,
  RIGHT,
}

interface HorizontalMoveControlProps {
  id: string;
  type: HorizontallyMoveableNodeType;
  index: number;
  rowIndex: number;
  title: string;
  direction: HorizontalMoveDirection;
  disabled: boolean;
}

const HorizontalMoveControl = ({
  id,
  type,
  index,
  rowIndex,
  title,
  direction,
  disabled,
}: HorizontalMoveControlProps) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (disabled) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      // depending on the node type, left/right movement is different in the diagram
      // for preventative-control-node, moving right visually decreases the index in the array, moving left visually increases it
      const indexStep = type === 'mitigating-control-node' ? HORIZONTAL_STEP : -HORIZONTAL_STEP;

      dispatch(
        moveNode({
          id,
          type: type as MoveableNodeTypes,
          currentIndex: index,
          targetIndex: direction === HorizontalMoveDirection.LEFT ? index - indexStep : index + indexStep,
          rowIndex,
        })
      );
    },
    [index, rowIndex, type, direction, disabled]
  );

  const icon = useMemo(
    () => (direction === HorizontalMoveDirection.LEFT ? <ArrowLeftIcon /> : <ArrowRightIcon />),
    [direction]
  );

  return (
    <div
      title={title}
      className={cn(
        'nopan nodrag bt-invisible bt-absolute bt-top-[calc(50%-12px)] bt-cursor-pointer group-hover:bt-visible',
        { '-bt-left-6': direction === HorizontalMoveDirection.LEFT },
        { '-bt-right-6': direction === HorizontalMoveDirection.RIGHT },
        { 'bt-hidden': disabled }
      )}
      onClick={handleClick}
    >
      {icon}
    </div>
  );
};

export default memo(HorizontalMoveControl);
