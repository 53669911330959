import { type Edge, type Node } from '@xyflow/react';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import { CauseDiagramNode, ConsequenceDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { id as causesContainerId } from '../components/container/causes-container-node.component';
import { id as consequencesContainerId } from '../components/container/consequences-container-node.component';
import { id as mitigatingControlsContainerId } from '../components/container/mitigating-controls-container-node.component';
import { id as preventativeControlsContainerId } from '../components/container/preventative-controls-container-node.component';
import { EdgeDirection } from './edge-util';

// spacing constants
const nodeWidth = 240, //px, defined on the base-node
  nodeHeight = 96, //px, defined on the base-node
  mueNodeWidth = 240, //px, defined on the mue-node
  mueNodeHeight = 128; //px, defined on the mue-node

const containerStartX = 0, // container start position on X axis
  containerStartY = 0, // container start position on Y axis
  containerPaddingTop = 80,
  containerPaddingBottom = 30,
  containerSpacingX = 30, // horizontal spacing between containers
  contaierToMueNodeSpacingX = 120, // horizontal spacing between containers and MUE node
  baseContainerHeight = 600; // default height of a container

const nodeStartX = 25, // node start position on X axis, relative to container
  nodeToEdgeDeltaY = 30, // desired vertical spacing between control node and edge
  nodeShiftDeltaX = 50; // horizontal shift of control nodes

const rowDeltaY = 50, // vertical spacing between rows
  cellDeltaX = 50; // horizontal spacing between nodes in same row

const baseContainerWidth = nodeWidth + 2 * nodeStartX, // base width of container to contain a single node (i.e. causes container),
  nodeSpacingY = nodeHeight / 2 + nodeToEdgeDeltaY, // computed vertical spacing (accounts for node height and desired vertical spacing)
  nodeStartY = nodeHeight / 2 + nodeToEdgeDeltaY + containerPaddingTop, // node start position on Y axis, relative to container
  rowHeight = 2 * nodeHeight + 2 * nodeToEdgeDeltaY; // computed height of a row used for calculating container height

/**
 * Generates nodes and edges configuration for a bow-tie diagram based on the provided diagram configuration.
 *
 * @param data - The diagram configuration containing causes, consequences, MUE (risk scenario), and hazard information
 *
 * @returns An object containing arrays of nodes and edges configured for rendering the bow-tie diagram
 * - nodes: Array of Node objects representing different elements like causes, consequences, controls, and containers
 * - edges: Array of Edge objects representing connections between nodes
 *
 * @remarks
 * The function performs the following:
 * 1. Calculates container sizes based on input data
 * 2. Creates container nodes for causes, consequences, and controls
 * 3. Generates individual nodes for causes, consequences, preventative controls, and mitigating controls
 * 4. Creates central MUE and hazard nodes
 * 5. Establishes edges between nodes to form the bowtie structure
 *
 * @example
 * ```typescript
 * const diagramConfig = {
 *   causes: [...],
 *   consequences: [...],
 *   mue: { id: 'mue1', label: 'Risk Scenario' },
 *   hazard: { id: 'hazard1', label: 'Hazard' }
 * };
 * const { nodes, edges } = generateNodesAndEdges(diagramConfig);
 * ```
 *
 * @typeParam DiagramConfiguration - The input configuration type containing the bow-tie diagram structure
 * @typeParam Node - The node configuration type for the diagram
 * @typeParam Edge - The edge configuration type for the diagram
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const generateNodesAndEdges = (diagramConfig: DiagramConfiguration, diagramMode = DIAGRAM_MODE.BOWTIE) => {
  const { mue, hazard, causes, consequences } = diagramConfig;

  // calculate container sizes based on data
  const { controlContainerWidth, controlContainerHeight } = calculateControlContainerSize(causes, consequences);

  // container positions
  const {
    causesContainerNodePosition,
    preventativeControlsContainerPosition,
    consequencesContainerNodePosition,
    mitigatingControlsContainerPosition,
  } = resolveContainerPositions(diagramMode, controlContainerWidth);

  /*** NODES ***/
  // Causes
  const causesContainerNode = {
    id: causesContainerId,
    type: 'causes-container',
    position: causesContainerNodePosition,
    data: {}, // data is hardcoded in the causes container node
    style: {
      width: `${baseContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const causesNodes = causes.map((cause, index) => {
    return {
      id: cause.id,
      parentId: causesContainerId,
      type: 'cause-node',
      position: { x: nodeStartX, y: nodeStartY + index * (rowHeight + rowDeltaY) },
      data: {
        recordId: cause.recordId,
        linkUrl: cause.linkUrl,
        label: cause.label,
        rowIndex: index,
        editMode: Boolean(cause.editMode),
        placeholder: 'Enter Cause',
      },
    };
  });

  // Preventative Controls
  const preventativeControlsContainer = {
    id: preventativeControlsContainerId,
    type: 'preventative-controls-container',
    position: preventativeControlsContainerPosition,
    data: {}, // data is hardcoded in the preventative controls container node
    style: {
      width: `${controlContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const preventativeControlNodes = causes.map((cause, currentCauseIndex) => {
    return cause.controls.map((control, index) => {
      const mod = index % 2; // modulos to determine top / bottom position
      const cell = Math.floor(index / 2); // division to determine the cell
      const delta = mod === 0 ? 0 : nodeShiftDeltaX; // delta to shift the node to the left

      // iterate between top / bottom positions
      const handleTypeKey = mod !== 0 ? 'topHandleType' : 'bottomHandleType';
      const handleIdKey = mod !== 0 ? 'topHandleId' : 'bottomHandleId';
      const handleIdValue = mod !== 0 ? 'top' : 'bottom';

      const causeNodeY = nodeStartY + currentCauseIndex * (rowHeight + rowDeltaY);

      const nodeDeltaY = (mod !== 0 ? 1 : -1) * nodeSpacingY; // delta to shift the node up / down

      return {
        id: control.id,
        parentId: preventativeControlsContainerId,
        type: 'preventative-control-node',
        position: {
          x: controlContainerWidth - nodeWidth - nodeStartX - cell * (nodeWidth + cellDeltaX) - delta,
          y: causeNodeY + nodeDeltaY,
        },
        data: {
          ...control,
          label: control.label,
          placeholder: 'Enter Control',
          cellIndex: index,
          rowIndex: currentCauseIndex,
          parentRecordId: cause.recordId,
          handles: {
            [handleIdKey]: handleIdValue,
            [handleTypeKey]: 'target',
          },
        },
      };
    });
  });

  // MUE (risk scenario)
  const mueNode = {
    id: mue.id,
    type: 'mue-node',
    position: {
      x: containerStartX + baseContainerWidth + controlContainerWidth + containerSpacingX + contaierToMueNodeSpacingX,
      y: controlContainerHeight / 2 - mueNodeHeight / 2,
    },
    data: {
      recordId: mue.recordId,
      linkUrl: mue.linkUrl,
      label: mue.label,
      editMode: Boolean(mue.editMode),
      placeholder: 'Enter Risk Scenario',
    },
  };

  // Hazard
  const hazardNode = hazard && {
    id: hazard.id,
    type: 'hazard-node',
    position: {
      x: mueNode.position.x + (mueNodeWidth - nodeWidth) / 2,
      y: 0,
    },
    data: {
      recordId: mue.recordId,
      label: hazard.label,
      editMode: Boolean(hazard.editMode),
      placeholder: 'Enter Hazard',
    },
  };

  // mitigating controls
  const mitigatingControlsContainer = {
    id: mitigatingControlsContainerId,
    type: 'mitigating-controls-container',
    position: mitigatingControlsContainerPosition,
    data: {}, // data is hardcoded in the mitigating controls container node
    style: {
      width: `${controlContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const mitigatingControlNodes = consequences.map((consequence, currentConsequenceIndex) => {
    return consequence.controls.map((control, index) => {
      const mod = index % 2; // modulos to determine top / bottom position
      const cell = Math.floor(index / 2); // division to determine the cell
      const delta = mod === 0 ? 0 : nodeShiftDeltaX; // delta to shift the node to the right

      // iterate between top / bottom
      const handleTypeKey = mod !== 0 ? 'topHandleType' : 'bottomHandleType';
      const handleIdKey = mod !== 0 ? 'topHandleId' : 'bottomHandleId';
      const handleIdValue = mod !== 0 ? 'top' : 'bottom';

      const consequnceNodeY = nodeStartY + currentConsequenceIndex * (rowHeight + rowDeltaY);

      const nodeDeltaY = (mod !== 0 ? 1 : -1) * nodeSpacingY; // delta to shift the node up / down

      return {
        id: control.id,
        parentId: mitigatingControlsContainerId,
        type: 'mitigating-control-node',
        position: {
          x: cell * (nodeWidth + cellDeltaX) + nodeStartX + delta,
          y: consequnceNodeY + nodeDeltaY,
        },
        data: {
          ...control,
          label: control.label,
          placeholder: 'Enter Control',
          cellIndex: index,
          rowIndex: currentConsequenceIndex,
          parentRecordId: consequence.recordId,
          handles: {
            [handleIdKey]: handleIdValue,
            [handleTypeKey]: 'target',
          },
        },
      };
    });
  });

  // consequences
  const consequencesContainerNode = {
    id: consequencesContainerId,
    type: 'consequences-container',
    position: consequencesContainerNodePosition,
    data: {}, // data is hardcoded in the consequences container node
    style: {
      width: `${baseContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const consequencesNodes = consequences.map((consequence, index) => {
    return {
      id: consequence.id,
      parentId: consequencesContainerId,
      type: 'consequence-node',
      position: { x: nodeStartX, y: nodeStartY + index * (rowHeight + rowDeltaY) },
      data: {
        recordId: consequence.recordId,
        linkUrl: consequence.linkUrl,
        label: consequence.label,
        rowIndex: index,
        editMode: Boolean(consequence.editMode),
        placeholder: 'Enter Consequence',
      },
    };
  });
  /*** NODES END ***/

  /*** EDGES ***/
  const hazardMueEdge = hazard && createEdge(hazard.id, mue.id, 'top', 'straight', { diagramMode });

  const causesEdges = causesNodes.map((causeNode, index) => {
    const controlNodeIds = preventativeControlNodes[index].map((control) => control.id);
    return createEdge(causeNode.id, mue.id, 'left', 'base-edge', {
      direction: EdgeDirection.LeftToRight,
      containerId: preventativeControlsContainerId,
      controls: controlNodeIds,
      rowIndex: index,
      parentRecordId: causeNode.data.recordId,
      diagramMode,
    });
  });

  const consequencesEdges = consequencesNodes.map((consequenceNode, index) => {
    const controlNodeIds = mitigatingControlNodes[index].map((control) => control.id);
    return createEdge(consequenceNode.id, mue.id, 'right', 'base-edge', {
      direction: EdgeDirection.RightToLeft,
      containerId: mitigatingControlsContainerId,
      controls: controlNodeIds,
      rowIndex: index,
      parentRecordId: consequenceNode.data.recordId,
      diagramMode,
    });
  });
  /*** EDGES END ***/

  // return diagram config of nodes and edges
  const nodes: Array<Node> = [
    causesContainerNode,
    ...causesNodes,
    preventativeControlsContainer,
    ...preventativeControlNodes.flat(),
    mueNode,
    mitigatingControlsContainer,
    ...mitigatingControlNodes.flat(),
    consequencesContainerNode,
    ...consequencesNodes,
  ];

  // add hazard node if hazard exists
  hazardNode && nodes.push(hazardNode);

  const edges: Array<Edge> = [...causesEdges, ...consequencesEdges];

  // add hazard-mue edge if hazard exists
  hazardMueEdge && edges.push(hazardMueEdge);

  return { nodes, edges };
};

/**
 * Creates an edge object representing a connection between two nodes in a flow graph.
 *
 * @param source - The ID of the source node where the edge starts
 * @param target - The ID of the target node where the edge ends
 * @param targetHandle - The handle identifier on the target node where the edge connects
 * @param type - The type of the edge
 * @param data - Optional additional data to be associated with the edge
 * @returns An edge object with generated ID and specified properties
 *
 * @example
 * ```typescript
 * const edge = createEdge('node1', 'node2', 'input1', 'default', { label: 'connection' });
 * ```
 */
const createEdge = (
  source: string,
  target: string,
  targetHandle: string,
  type: string,
  data?: Record<string, unknown>
) => {
  return {
    id: `${source}-${target}`,
    source,
    target,
    targetHandle,
    type,
    data,
  };
};

/**
 * Calculates the dimensions of a control container based on the number of causes and consequences.
 *
 * @param causes - An array of cause diagram nodes, each containing a list of controls
 * @param consequences - An array of consequence diagram nodes, each containing a list of controls
 *
 * @returns An object containing the calculated width and height of the control container
 *   - controlContainerWidth: The width calculated based on the maximum number of controls in a single line
 *   - controlContainerHeight: The height calculated based on the maximum number of rows needed
 */
const calculateControlContainerSize = (
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  // calculate container sizes based on data
  const maxPreventativeControlsInOneRow = Math.max(...causes.map((cause) => cause.controls.length), 1);
  const maxMitigatingControlsInOneRow = Math.max(...consequences.map((consequence) => consequence.controls.length), 1);

  const maxControlsInOneRowSection = Math.ceil(
    Math.max(maxPreventativeControlsInOneRow, maxMitigatingControlsInOneRow) / 2
  ); // top or bottom

  let controlContainerWidth = baseContainerWidth;
  if (maxControlsInOneRowSection > 1) {
    controlContainerWidth =
      maxControlsInOneRowSection * nodeWidth + (maxControlsInOneRowSection - 1) * cellDeltaX + 2 * nodeStartX;
  }

  // adjust width for the shift of the last node if the number of controls is even
  if (maxPreventativeControlsInOneRow % 2 === 0 || maxMitigatingControlsInOneRow % 2 === 0) {
    controlContainerWidth += nodeShiftDeltaX;
  }

  const containerMaxRows = Math.max(causes.length, consequences.length, 1);

  const controlContainerHeight = Math.max(
    baseContainerHeight,
    containerMaxRows * rowHeight + (containerMaxRows - 1) * rowDeltaY + containerPaddingTop + containerPaddingBottom
  );

  return { controlContainerWidth, controlContainerHeight };
};

/**
 * Resolves the positions of containers in the bow-tie diagram.
 *
 * @param diagramMode - The mode of the diagram (BOWTIE or BUTTERFLY)
 * @param controlContainerWidth - The calculated width of the control container
 *
 * @returns An object containing position coordinates for different containers:
 *  - causesContainerNodePosition: Position of the causes container
 *  - preventativeControlsContainerPosition: Position of the preventative controls container
 *  - consequencesContainerNodePosition: Position of the consequences container
 *  - mitigatingControlsContainerPosition: Position of the mitigating controls container
 *
 * Each position is represented as an object with x and y coordinates
 */
const resolveContainerPositions = (diagramMode: DIAGRAM_MODE, controlContainerWidth: number) => {
  const causesContainerNodePosition =
    diagramMode === DIAGRAM_MODE.BOWTIE
      ? { x: containerStartX, y: containerStartY }
      : { x: containerStartX + controlContainerWidth + containerSpacingX, y: containerStartY };

  const preventativeControlsContainerPosition =
    diagramMode === DIAGRAM_MODE.BOWTIE
      ? { x: containerStartX + baseContainerWidth + containerSpacingX, y: containerStartY }
      : { x: containerStartX, y: containerStartY };

  const consequencesContainerNodePosition =
    diagramMode === DIAGRAM_MODE.BOWTIE
      ? {
          x:
            containerStartX +
            baseContainerWidth +
            2 * controlContainerWidth +
            2 * containerSpacingX +
            2 * contaierToMueNodeSpacingX +
            mueNodeWidth,
          y: containerStartY,
        }
      : {
          x:
            containerStartX +
            baseContainerWidth +
            controlContainerWidth +
            containerSpacingX +
            2 * contaierToMueNodeSpacingX +
            mueNodeWidth,
          y: containerStartY,
        };

  const mitigatingControlsContainerPosition =
    diagramMode === DIAGRAM_MODE.BOWTIE
      ? {
          x:
            containerStartX +
            baseContainerWidth +
            controlContainerWidth +
            containerSpacingX +
            2 * contaierToMueNodeSpacingX +
            mueNodeWidth,
          y: containerStartY,
        }
      : {
          x:
            containerStartX +
            2 * baseContainerWidth +
            controlContainerWidth +
            2 * containerSpacingX +
            2 * contaierToMueNodeSpacingX +
            mueNodeWidth,
          y: containerStartY,
        };

  return {
    causesContainerNodePosition,
    preventativeControlsContainerPosition,
    consequencesContainerNodePosition,
    mitigatingControlsContainerPosition,
  };
};
