import { configureStore } from '@reduxjs/toolkit';
import { enhancedBowtieApi as bowtieApi } from '../api/enhanced/enhanced-bowtie-api';
import { enhancedV4Api as v4Api } from '../api/enhanced/enhanced-v4-api';
import legacyDiagramReducer from './diagram/diagramReducer';
import { listenerMiddleware } from './middleware';
import flowDiagram from './slices/diagram';
import runningJobs from './slices/running-jobs';

const store = configureStore({
  reducer: {
    [v4Api.reducerPath]: v4Api.reducer,
    [bowtieApi.reducerPath]: bowtieApi.reducer,
    diagram: legacyDiagramReducer,
    flowDiagram,
    runningJobs,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware).concat(v4Api.middleware).concat(bowtieApi.middleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
