import React, { createContext, useContext, useMemo } from 'react';

type DiagramContextValue = {
  isAIGenerated: boolean;
  isNewDiagram: boolean;
  isSavedDiagram: boolean;
};

interface DiagramContextProps {
  isAIGenerated: boolean;
  isNewDiagram: boolean;
  isSavedDiagram: boolean;
  children: React.ReactNode;
}

const DiagramContext = createContext<DiagramContextValue | undefined>(undefined);

export const DiagramContextProvider: React.FC<DiagramContextProps> = ({
  isAIGenerated,
  isNewDiagram,
  isSavedDiagram,
  children,
}: DiagramContextProps) => {
  const value = useMemo(
    () => ({ isAIGenerated, isNewDiagram, isSavedDiagram }),
    [isAIGenerated, isNewDiagram, isSavedDiagram]
  );
  return <DiagramContext.Provider value={value}>{children}</DiagramContext.Provider>;
};

// context consumer hook
export const useDiagramContext = () => {
  const context = useContext(DiagramContext);

  if (context === undefined) {
    throw new Error('useDiagramContext must be used within DiagramContextProvider');
  }

  return context;
};
