import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { IdValueType } from '../../../@types/common';
import { TransformedSimpleRecordDto } from '../../../api/@types/enhanced-v4-api.types';
import ConfirmActionToast from '../../../components/common/confirm-action-toast.component';
import { useAppPermissionsContext } from '../../../context/app-permissions.context';
import { cn } from '../../../helpers/util';
import { useAppDispatch } from '../../../redux/hooks';
import { removeNode, updateNode } from '../../../redux/slices/diagram';
import { ControlDiagramNode } from '../../@types/diagram';
import { ControlNodeTypes, LeafNodeTypes, MutableNodeTypes } from '../../util/node-util';
import Handles, { HandlesProps } from './common/handles.component';
import NodeTextArea from './common/node-text-area.component';
import LinkIcon from './icons/link-icon.component';
import UnlinkIcon from './icons/unlink-icon.component';

const unlinkConfirmationToastId = 'unlinkConfirmation';

export type BaseNodeData = {
  recordId?: number;
  parentRecordId?: number;
  label?: string;
  controlType?: IdValueType;
  controlEffectiveness?: IdValueType;
  linkUrl?: string;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  iconClassName?: string;
  globalIconClassName?: string;
  cellIndex?: number; // cell index in the diagram, matches the control index in the linked record field array of a cause/consequence
  rowIndex?: number; // row index in the diagram, matches the cause/consequence index in the mue record linked record field array
  editMode?: boolean;
  showRecordIndicators?: boolean;
  showUnlinkActionIcon?: boolean;
  showControlType?: boolean;
  showControlEffectiveness?: boolean;
  filtered?: boolean;
  draft?: boolean;
  global?: boolean;
  handles?: HandlesProps;
};
type BaseNodeType = Node<BaseNodeData>;
type BaseNodeProps = NodeProps<BaseNodeType>;

/**
 * BaseNode component represents a node in a flow diagram with optional edit mode and various indicators.
 *
 * @component
 * @param {BaseNodeProps} data - The properties for the BaseNode component.
 * @param {string} data.label - The label of the node.
 * @param {string} [data.className] - Additional class names for the node container.
 * @param {string} [data.iconClassName] - Class names for the action icons.
 * @param {string} [data.globalIconClassName] - Class names for the global indicator icon.
 * @param {boolean} [data.editMode=false] - Flag to enable edit mode.
 * @param {boolean} [data.showRecordIndicators=false] - Flag to show record indicators.
 * @param {boolean} [data.draft=false] - Flag to indicate if the node is a draft.
 * @param {boolean} [data.global=false] - Flag to indicate if the node is global.
 * @param {object} [data.handles={}] - Configuration for edge connection targets.
 *
 * @example
 * <BaseNode data={nodeData} />
 *
 * @returns {JSX.Element} The rendered BaseNode component.
 */
const BaseNode = ({ id, type, data }: BaseNodeProps): JSX.Element => {
  const {
    recordId,
    parentRecordId,
    linkUrl,
    label,
    controlType,
    controlEffectiveness,
    placeholder,
    className,
    labelClassName = 'bt-line-clamp-4',
    iconClassName,
    globalIconClassName,
    rowIndex,
    editMode = false,
    showRecordIndicators = false,
    showUnlinkActionIcon = false,
    showControlType = false,
    showControlEffectiveness = false,
    filtered = false,
    draft = false,
    global = false,
    handles = {},
  } = data;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { updateNodeData } = useReactFlow();
  const dispatch = useAppDispatch();

  const showLinkActionIcon = Boolean(linkUrl);
  const canShowControlType = controlType?.value && showControlType;
  const canShowControlEffectiveness = controlEffectiveness?.value && showControlEffectiveness;

  const { resolvePermissionsByNodeType } = useAppPermissionsContext();
  const { editNodePermission: canEditNode, removeNodePermission: canRemoveNode } = resolvePermissionsByNodeType(type);

  useEffect(() => {
    // dismiss the unlink toast when node renders
    if (toast.isActive(unlinkConfirmationToastId)) {
      toast.dismiss(unlinkConfirmationToastId);
    }
  });

  useEffect(() => {
    if (editMode === true && inputRef.current) {
      // Using only autoFocus on the textarea places the cursor at the beginning of the text
      // (https://github.com/facebook/react/issues/14125)
      const defaultValue = label ?? '';
      inputRef.current.defaultValue = defaultValue;
      inputRef.current.setSelectionRange(defaultValue.length, defaultValue.length);

      // Focus the textarea after component enters edit mode
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [editMode]);

  const updateWithExistingRecord = useCallback(
    (data: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }) => {
      if (canEditNode) {
        dispatch(
          updateNode({
            id,
            type: type as ControlNodeTypes,
            data: { ...data, editMode: false, parentRecordId },
            rowIndex,
          })
        );
      }
    },
    [id, rowIndex, parentRecordId, canEditNode]
  );

  const handleOnKeyDownEvent = useCallback(
    (event: React.KeyboardEvent, data?: Partial<ControlDiagramNode> & { control: TransformedSimpleRecordDto }) => {
      if (canEditNode) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          if (data) {
            updateWithExistingRecord(data);
          } else {
            const updatedLabel = inputRef.current?.value.trim();
            if (updatedLabel) {
              dispatch(
                updateNode({
                  id,
                  type: type as MutableNodeTypes,
                  data: { label: updatedLabel, editMode: false, recordId, parentRecordId },
                  rowIndex,
                })
              );
            }
          }
        } else if (event.code === 'Escape') {
          if (!label && !recordId) {
            dispatchRemoveNode();
          } else {
            updateNodeData(id, { editMode: false });
          }
        }
      }
    },
    [id, label, rowIndex, recordId, parentRecordId, updateWithExistingRecord, canEditNode]
  );

  const handleLinkAction = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      window.open(linkUrl);
    },
    [linkUrl]
  );

  const dispatchRemoveNode = useCallback(
    (forceRemove?: boolean) => {
      if (forceRemove || canRemoveNode) {
        dispatch(removeNode({ id, type: type as LeafNodeTypes, data: { ...data, id }, rowIndex }));
      }
    },
    [rowIndex, id, recordId, parentRecordId, type, canRemoveNode]
  );

  const handleOnBlur = useCallback(() => {
    if (!label && !recordId && editMode === true) {
      dispatchRemoveNode(true);
    }
  }, [label, recordId, editMode, dispatchRemoveNode]);

  const handleUnlinkAction = useCallback(
    (event: React.MouseEvent) => {
      if (canRemoveNode) {
        event.preventDefault();
        event.stopPropagation();

        if (recordId) {
          toast(
            (props) => (
              <ConfirmActionToast
                {...props}
                onConfirmActionClick={dispatchRemoveNode}
                confirmActionLabel="Unlink"
                message={`Are you sure you want to unlink ${label!}`}
              />
            ),
            {
              autoClose: 10_000, // auto close after 10 seconds
              toastId: unlinkConfirmationToastId,
            }
          );
        } else {
          dispatchRemoveNode();
        }
      }
    },
    [dispatchRemoveNode, label, recordId, canRemoveNode]
  );

  return (
    <div
      className={cn(
        'nopan nodrag bt-group bt-relative bt-flex bt-h-24 bt-w-60 bt-flex-col bt-items-center bt-justify-center bt-rounded-md bt-border bt-border-gray-2 bt-text-xs bt-shadow-md',
        className,
        { 'bt-border-dashed bt-bg-transparent': editMode && canEditNode },
        { 'bt-hidden': filtered }
      )}
      title={label}
      data-node-type={type}
    >
      {!editMode && (
        <div className={cn('bt-flex bt-h-full bt-w-full bt-content-center bt-p-0.5 bt-text-center', labelClassName)}>
          {label}
        </div>
      )}

      {!editMode && (canShowControlType || canShowControlEffectiveness) && (
        <div
          className={
            'bt-mt-auto bt-flex bt-h-8 bt-w-full bt-flex-col bt-rounded-b-md bt-border-t bt-border-gray-2 bt-bg-gray-4 bt-p-0.5'
          }
        >
          {canShowControlType && (
            <p className="bt-truncate bt-text-xxs" title={controlType.value}>
              Control Type: {controlType.value}
            </p>
          )}
          {canShowControlEffectiveness && (
            <p className="bt-truncate bt-text-xxs" title={controlEffectiveness?.value}>
              Control Effectiveness: {controlEffectiveness?.value}
            </p>
          )}
        </div>
      )}

      {editMode && canEditNode && (
        <NodeTextArea
          ref={inputRef}
          defaultValue={label}
          type={type}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDownEvent}
          onItemClick={updateWithExistingRecord}
          onBlur={handleOnBlur}
        />
      )}

      {/* edge connection targets */}
      <Handles {...handles} />

      {/* draft and global record indicators */}
      {showRecordIndicators && global && (
        <FontAwesomeIcon
          icon={faGlobe}
          className={cn('bt-absolute -bt-bottom-1 -bt-left-2 bt-h-3 bt-w-3', globalIconClassName)}
          title="Global control"
        />
      )}
      {showRecordIndicators && !global && draft && (
        <div
          title="Draft"
          className={cn('bt-absolute -bt-bottom-1 -bt-left-2 bt-h-3 bt-w-3 bt-rounded-full bt-border-2', className)}
        />
      )}
      {/* on hover action icons */}
      {showLinkActionIcon && (
        <div
          title="Open in Viking"
          className={cn(
            'bt-invisible bt-absolute -bt-right-2 -bt-top-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
          onClick={handleLinkAction}
        >
          <LinkIcon className={iconClassName} />
        </div>
      )}
      {showUnlinkActionIcon && canRemoveNode && (
        <div
          title="Unlink"
          className={cn(
            'bt-invisible bt-absolute -bt-bottom-2 -bt-right-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
          onClick={handleUnlinkAction}
        >
          <UnlinkIcon className={iconClassName} />
        </div>
      )}
    </div>
  );
};

export default memo(BaseNode);
