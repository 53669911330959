import { compact } from 'lodash';
import { isObject, isSuccessResponse } from '../helpers/type-guards';
import { BowtieBasicRecord, BowtieCauseRecord, BowtieConsequenceRecord, BowtieMappedData } from './bowtie-data-types';
import { BowtieConfiguration } from './common-data-types';
import { RecordResult } from './record-data-types';
import { RecordService } from './record-service';
import { ServiceBase } from './service-base';

export class BowtieService extends ServiceBase {
  private readonly requiredMitigatingControlsFields = ['consequencesRecordLinkFieldId'];
  private readonly requiredPreventativeControlFields = ['causesRecordLinkFieldId'];

  private readonly recordService: RecordService;

  constructor(env: string) {
    super(env);

    this.recordService = new RecordService(env);
  }

  public async mapBowtieData(record: RecordResult, bowtieConfiguration: BowtieConfiguration) {
    const bowtieData: BowtieMappedData = {
      scenarioRecord: record,
    };

    if (!record.fields) {
      throw new Error("Missing parameter 'fields' in record body");
    }

    if (!bowtieConfiguration.main.scenarioFieldId) {
      throw new Error("Missing parameter 'scenario' in bowtie configuration");
    }

    if (!bowtieConfiguration.controls.mitigatingControls) {
      throw new Error("Missing parameter 'mitigatingControls' in bowtie configuration");
    }

    if (bowtieConfiguration.controls.mitigatingControls) {
      const mitigatingControls = bowtieConfiguration.controls.mitigatingControls as unknown as Record<string, unknown>;
      this.requiredMitigatingControlsFields.forEach((requiredField) => {
        if (!mitigatingControls[requiredField]) {
          throw new Error(`Missing parameter '${requiredField}' in 'mitigatingControls' field`);
        }
      });
    }

    if (!bowtieConfiguration.controls.preventativeControls) {
      throw new Error("Missing parameter 'preventativeControls' in bowtie configuration");
    }

    if (bowtieConfiguration.controls.preventativeControls) {
      const preventativeControls = bowtieConfiguration.controls.preventativeControls as unknown as Record<
        string,
        unknown
      >;
      this.requiredPreventativeControlFields.forEach((requiredField) => {
        if (!preventativeControls[requiredField]) {
          throw new Error(`Missing parameter '${requiredField}' in 'preventativeControls' field`);
        }
      });
    }

    const { fields } = record; // risk scenario record fields

    const {
      main: { scenarioFieldId, hazardFieldId },
    } = bowtieConfiguration;

    const scenarioCaptionValue = fields[scenarioFieldId];

    // Adding bowtie configuration
    bowtieData.bowtieConfiguration = bowtieConfiguration;
    bowtieData.scenario = scenarioCaptionValue;

    if (hazardFieldId) {
      const hazardField = fields[hazardFieldId];
      bowtieData.hazard = hazardField ?? null;
    }

    // Fetching all mitigating controls with consequences and preventative controls with causes
    const bowtieDataPromisePool = await Promise.all([
      this.fetchMitigatingControlsWithConsequences(bowtieConfiguration, fields),
      this.fetchPreventativeControlsWithCauses(bowtieConfiguration, fields),
    ]);

    const mitigatingControlRecords = bowtieDataPromisePool[0];
    const preventativeControlRecords = bowtieDataPromisePool[1];

    if (mitigatingControlRecords && mitigatingControlRecords.records) {
      bowtieData.mitigatingControls = [...compact(mitigatingControlRecords.records)];
    } else {
      bowtieData.mitigatingControls = [];
    }

    if (preventativeControlRecords && preventativeControlRecords.records) {
      bowtieData.preventativeControls = [...compact(preventativeControlRecords.records)];
    } else {
      bowtieData.preventativeControls = [];
    }

    const uniqueCauses = preventativeControlRecords?.causes || [];

    const uniqueConsequences = mitigatingControlRecords?.consequences || [];

    bowtieData.causes = uniqueCauses.map((uniqueCause) => {
      const ownPreventativeControls = bowtieData.preventativeControls?.filter(
        (preventativeControl) =>
          preventativeControl &&
          preventativeControl.causes &&
          preventativeControl.causes.length > 0 &&
          preventativeControl.causes.find((cause) => cause && cause && cause.id === uniqueCause.id)
      );

      return {
        ...uniqueCause,
        preventativeControls: ownPreventativeControls,
        form: { formId: bowtieConfiguration.causes.formId },
      };
    });

    bowtieData.consequences = uniqueConsequences.map((uniqueConsequence) => {
      const ownMitigatingControls = bowtieData.mitigatingControls?.filter(
        (mitigatingControl) =>
          mitigatingControl &&
          mitigatingControl.consequences &&
          mitigatingControl.consequences.length > 0 &&
          mitigatingControl.consequences.find(
            (consequence) => consequence && consequence && consequence.id === uniqueConsequence.id
          )
      );

      return {
        ...uniqueConsequence,
        mitigatingControls: ownMitigatingControls,
        form: { formId: bowtieConfiguration.consequences.formId },
      };
    });

    const standaloneCauses = localStorage.getItem('standaloneCauses' + '_' + window.location.search);
    const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);

    if (standaloneCauses) {
      const parsedStandaloneCauses = JSON.parse(standaloneCauses) as Array<BowtieBasicRecord>;
      const dataCauses = bowtieData.causes;
      bowtieData.causes = [
        ...dataCauses,
        ...(dataCauses
          ? parsedStandaloneCauses.filter(
              (cause) => dataCauses.findIndex((dataCause) => dataCause.id === cause.uuid) === -1
            )
          : parsedStandaloneCauses),
      ];
    }

    if (standaloneConsequences) {
      const parsedStandaloneConsequences = JSON.parse(standaloneConsequences) as Array<BowtieBasicRecord>;
      const dataConsequences = bowtieData.consequences;
      bowtieData.consequences = [
        ...dataConsequences,
        ...(dataConsequences
          ? parsedStandaloneConsequences.filter(
              (consequence) =>
                dataConsequences.findIndex((dataConsequence) => dataConsequence.id === consequence.uuid) === -1
            )
          : parsedStandaloneConsequences),
      ];
    }

    bowtieData.causes = bowtieData.causes.sort((a, b) => a.id! - b.id!);
    bowtieData.consequences = bowtieData.consequences.sort((a, b) => a.id! - b.id!);
    return bowtieData;
  }

  async fetchMitigatingControlsWithConsequences(
    bowtieConfiguration: BowtieConfiguration,
    scenarioRecordFields: Record<string, unknown>
  ) {
    const {
      main: { mitigatingControlsRecordLinkFieldId, consequencesReverseRecordLinkFieldId },
      consequences: { fieldId: consequenceCaptionFieldId },
      controls: {
        fieldId: controlCaptionFieldId,
        criticalOrNonCriticalFieldId,
        effectiveOrNotEffectiveField,
        mitigatingControls: { consequencesRecordLinkFieldId },
      },
    } = bowtieConfiguration;

    // fetch the risk scenario record consequences
    const scenarioConsequences = scenarioRecordFields[consequencesReverseRecordLinkFieldId] as Array<number>;

    if (scenarioConsequences && scenarioConsequences.length > 0) {
      const consequencesRecordPayload = await this.recordService.fetchRecords(
        bowtieConfiguration.consequences.formId,
        scenarioConsequences
      );

      let mappedConsequences: Array<BowtieBasicRecord> = [];

      if (
        consequencesRecordPayload &&
        isSuccessResponse(consequencesRecordPayload) &&
        consequencesRecordPayload?.payload?.data?.result?.results.length > 0
      ) {
        const consequences = consequencesRecordPayload.payload.data.result.results;

        mappedConsequences = consequences
          .filter((consequence) => consequence.fields)
          .map<BowtieBasicRecord>((consequence) => {
            const consequencesCaptionFieldValue = consequence.fields[consequenceCaptionFieldId];

            return {
              id: consequence && consequence.id,
              uuid: consequence && consequence.id,
              value: consequencesCaptionFieldValue as string,
              status: consequence.status,
              linkUrl: consequence?.linkUrl?.length ? consequence.linkUrl : undefined,
            };
          });
      }

      // fetch the risk scenario (mitigating) control records
      const scenarioMitigatingControls = scenarioRecordFields[mitigatingControlsRecordLinkFieldId] as Array<unknown>;

      let mappedRecords: Array<BowtieConsequenceRecord> = [];

      if (
        scenarioMitigatingControls &&
        Array.isArray(scenarioMitigatingControls) &&
        scenarioMitigatingControls.length > 0
      ) {
        const recordsPayload = await this.recordService.fetchRecords(
          bowtieConfiguration.controls.formId,
          scenarioMitigatingControls as number[]
        );

        if (isSuccessResponse(recordsPayload) && recordsPayload?.payload?.data?.result?.results.length > 0) {
          const records = recordsPayload.payload.data.result.results;

          const consequences = records
            .filter((record) => record.fields)
            .map((record) => {
              let consequencesRecords = record.fields[consequencesRecordLinkFieldId];

              if (
                Array.isArray(consequencesRecords) &&
                Array.isArray(scenarioConsequences) &&
                consequencesRecords.length > 0
              ) {
                consequencesRecords = consequencesRecords.filter(
                  (consequenceId) =>
                    scenarioConsequences.findIndex(
                      (scenarioConsequenceId) => String(scenarioConsequenceId) === consequenceId
                    ) > -1
                );

                return {
                  recordId: record.id,
                  consequenceId: consequencesRecords,
                };
              } else {
                return { recordId: null, consequenceId: null };
              }
            });

          mappedRecords = records
            .filter((record) => record.fields)
            .map<BowtieConsequenceRecord>((record) => {
              let effectiveOrNotEffectiveFieldValue = null;
              const captionField = record.fields[controlCaptionFieldId];

              const criticalOrNonCriticalFieldValue = record.fields[criticalOrNonCriticalFieldId];

              const indexedRecord = record as unknown as Record<string, unknown>;

              if (effectiveOrNotEffectiveField && indexedRecord[effectiveOrNotEffectiveField]) {
                effectiveOrNotEffectiveFieldValue = {
                  value: {
                    value: indexedRecord[effectiveOrNotEffectiveField] as string,
                  },
                };
              } else {
                effectiveOrNotEffectiveFieldValue = record.fields[effectiveOrNotEffectiveField] as {
                  value: string;
                };
              }

              const recordConsequencesFields = consequences.find((consequence) => consequence.recordId === record.id)
                ?.consequenceId as Array<unknown>;

              const globalField = record.fields['Global'];

              return {
                id: record.id,
                value: captionField as string,
                uuid: record.id,
                status: record.status,
                linkUrl: record?.linkUrl?.length ? record.linkUrl : undefined,
                consequences: recordConsequencesFields
                  ? mappedConsequences.filter((consequence) =>
                      recordConsequencesFields.includes(String(consequence.id))
                    )
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveFieldValue && isObject(effectiveOrNotEffectiveFieldValue)
                    ? (effectiveOrNotEffectiveFieldValue.value as { value: string })
                    : undefined,
                criticalOrNonCritical:
                  criticalOrNonCriticalFieldValue && isObject(criticalOrNonCriticalFieldValue)
                    ? criticalOrNonCriticalFieldValue.value
                    : undefined,
                global: Boolean(globalField),
              };
            });
        }
      }

      return mappedRecords && mappedRecords.length > 0
        ? {
            records: mappedRecords,
            consequences: mappedConsequences,
          }
        : { records: [], consequences: mappedConsequences };
    }

    return { records: [], consequences: [] };
  }

  async fetchPreventativeControlsWithCauses(
    bowtieConfiguration: BowtieConfiguration,
    scenarioRecordFields: Record<string, unknown>
  ) {
    const {
      main: { preventativeControlsRecordLinkFieldId, causesReverseRecordLinkFieldId },
      causes: { fieldId: causeCaptionField },
      controls: {
        fieldId: controlCaptionFieldId,
        criticalOrNonCriticalFieldId,
        effectiveOrNotEffectiveField,
        preventativeControls: { causesRecordLinkFieldId },
      },
    } = bowtieConfiguration;

    // fetch the risk scenario causes
    const scenarioCauses = scenarioRecordFields[causesReverseRecordLinkFieldId] as Array<number>;

    if (scenarioCauses && scenarioCauses.length > 0) {
      const causesRecordPayload = await this.recordService.fetchRecords(
        bowtieConfiguration?.causes?.formId,
        scenarioCauses
      );

      let mappedCauses: Array<BowtieBasicRecord> = [];

      if (
        causesRecordPayload &&
        isSuccessResponse(causesRecordPayload) &&
        causesRecordPayload?.payload?.data?.result?.results.length > 0
      ) {
        const causes = causesRecordPayload.payload.data.result.results;

        mappedCauses = causes
          .filter((cause) => cause.fields)
          .map<BowtieBasicRecord>((cause) => {
            const causesCaptionFieldValue = cause.fields[causeCaptionField];

            return {
              id: cause && cause.id,
              uuid: cause && cause.id,
              status: cause.status,
              value: causesCaptionFieldValue as string,
              linkUrl: cause?.linkUrl?.length ? cause.linkUrl : undefined,
            };
          });
      }

      // fetch the (preventative) control records
      const scenarioPreventativeControls = scenarioRecordFields[
        preventativeControlsRecordLinkFieldId
      ] as Array<unknown>;

      let mappedRecords: Array<BowtieCauseRecord> = [];

      if (
        scenarioPreventativeControls &&
        Array.isArray(scenarioPreventativeControls) &&
        scenarioPreventativeControls.length > 0
      ) {
        const recordsPayload = await this.recordService.fetchRecords(
          bowtieConfiguration.controls.formId,
          scenarioPreventativeControls as number[]
        );

        if (isSuccessResponse(recordsPayload) && recordsPayload?.payload?.data?.result?.results.length > 0) {
          const records = recordsPayload.payload.data.result.results;

          const causes = records
            .filter((record) => record.fields)
            .map((record) => {
              let causesRecords = record.fields[causesRecordLinkFieldId] as Array<string>;

              if (Array.isArray(causesRecords) && Array.isArray(scenarioCauses) && causesRecords.length > 0) {
                causesRecords = causesRecords.filter(
                  (causeId) => scenarioCauses.findIndex((scenarioCauseId) => String(scenarioCauseId) === causeId) > -1
                );

                return {
                  recordId: record.id || null,
                  causeIds: causesRecords,
                };
              } else {
                return { recordId: null, causeIds: [] };
              }
            });

          mappedRecords = records
            .filter((record) => record.fields)
            .map<BowtieCauseRecord>((record) => {
              let effectiveOrNotEffectiveFieldValue = null;
              const captionField = record.fields[controlCaptionFieldId];

              const criticalOrNonCriticalFieldValue = record.fields[criticalOrNonCriticalFieldId];

              const indexedRecord = record as unknown as Record<string, unknown>;
              if (effectiveOrNotEffectiveField && indexedRecord[effectiveOrNotEffectiveField]) {
                effectiveOrNotEffectiveFieldValue = {
                  value: {
                    value: indexedRecord[effectiveOrNotEffectiveField] as string,
                  },
                };
              } else {
                effectiveOrNotEffectiveFieldValue = record.fields[effectiveOrNotEffectiveField] as {
                  value: string;
                };
              }

              const recordCausesFields = causes.find((cause) => cause.recordId === record.id)?.causeIds;

              const globalField = record.fields['Global'];

              return {
                id: record && record.id,
                uuid: record && record.id,
                value: captionField as string,
                status: record.status,
                linkUrl: record?.linkUrl?.length ? record.linkUrl : undefined,
                causes: recordCausesFields
                  ? mappedCauses.filter((cause) => recordCausesFields.includes(String(cause.id)))
                  : [],
                effectiveOrNotEffective:
                  effectiveOrNotEffectiveFieldValue && isObject(effectiveOrNotEffectiveFieldValue)
                    ? (effectiveOrNotEffectiveFieldValue.value as { value: string })
                    : undefined,
                criticalOrNonCritical:
                  criticalOrNonCriticalFieldValue && isObject(criticalOrNonCriticalFieldValue)
                    ? criticalOrNonCriticalFieldValue.value
                    : undefined,
                global: Boolean(globalField),
              };
            });
        }
      }

      return mappedRecords && mappedRecords.length > 0
        ? {
            records: mappedRecords,
            causes: mappedCauses,
          }
        : { records: [], causes: mappedCauses };
    }

    return { records: [], causes: [] };
  }
}
