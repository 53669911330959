import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { cloneDeep } from 'lodash';
import { DynamicFormSettings, DynamicFormSettingsWrapper } from '../../@types/form-data-types';
import { BowtieConfiguration } from '../../services/common-data-types';
import {
  TransformedCreateAndStartBowtieRecordsCreationJobResultType,
  TransformedCreateSimpleRecordsApiArg,
  TransformedCreateSimpleRecordsApiResponse,
  TransformedFieldOptionDtoObject,
  TransformedGetAddon1ResultType,
  TransformedGetAddonMappingsResultType,
  TransformedGetBackgroundJobDetailsResultType,
  TransformedGetSimpleCurrentUserApiArg,
  TransformedGetSimpleCurrentUserResultType,
  TransformedGetSimpleRecordResultType,
  TransformedGetSimpleRecords1ApiArg,
  TransformedGetSimpleRecords1ResultType,
  TransformedGetSimpleUsersApiArg,
  TransformedGetSimpleUsersResultType,
  TransformedPatchSimpleApiArg,
} from '../@types/enhanced-v4-api.types';
import {
  BowtieConfigDto,
  CreateAndStartBowtieRecordsCreationJobApiArg,
  CreateAndStartBowtieRecordsCreationJobApiResponse,
  CreateSimpleRecordsApiResponse,
  EntityResponseDtoAddonResultDto,
  GetAddon1ApiArg,
  GetAddonMappingsApiArg,
  GetAddonMappingsApiResponse,
  GetBackgroundJobDetailsApiArg,
  GetBackgroundJobDetailsApiResponse,
  GetBowtieConfigurationApiArg,
  GetBowtieConfigurationApiResponse,
  GetSimpleCurrentUserApiResponse,
  GetSimpleOptionsForFieldApiArg,
  GetSimpleOptionsForFieldApiResponse,
  GetSimpleRecordApiArg,
  GetSimpleRecordApiResponse,
  GetSimpleRecords1ApiResponse,
  GetSimpleUsersApiResponse,
  PatchSimpleApiResponse,
  SetBowtieConfigurationApiArg,
  SetBowtieConfigurationApiResponse,
  UserRequestDtoObjectRead,
  v4Api,
} from '../generated/v4-api';

// Overrides to ensure the correct types are used
export const _enhancedV4Api = v4Api
  .enhanceEndpoints({
    addTagTypes: ['bowtie-configuration'],
  })
  .injectEndpoints({
    overrideExisting: true,

    endpoints: (builder) => ({
      createAndStartBowtieRecordsCreationJob: builder.mutation<
        TransformedCreateAndStartBowtieRecordsCreationJobResultType,
        CreateAndStartBowtieRecordsCreationJobApiArg
      >({
        query: (queryArg) => ({
          url: `/v4/background-jobs/create-bowtie-records`,
          method: 'POST',
          body: queryArg.bowtieDto,
        }),
        transformResponse: (
          response: CreateAndStartBowtieRecordsCreationJobApiResponse
        ): TransformedCreateAndStartBowtieRecordsCreationJobResultType => {
          return response.result;
        },
      }),
      createSimpleRecords: builder.mutation<
        TransformedCreateSimpleRecordsApiResponse,
        TransformedCreateSimpleRecordsApiArg
      >({
        query: (queryArg) => ({
          url: '/v4/records/simple?keyType=id',
          method: 'POST',
          body: queryArg.body,
          params:
            Object.keys(queryArg.params).length === 0
              ? undefined
              : {
                  params: queryArg.params,
                },
          validateStatus: (response, result) => response.status === 200 && result?.result,
        }),
        transformResponse: (response: CreateSimpleRecordsApiResponse) => {
          return response.result ?? [];
        },
      }),
      getAddon1: builder.query<TransformedGetAddon1ResultType, GetAddon1ApiArg>({
        query: (queryArg) => ({
          url: `/v4/add-ons/${queryArg.id}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        }),
        transformResponse: ({
          result: { result } = {},
        }: EntityResponseDtoAddonResultDto): TransformedGetAddon1ResultType => {
          const bowtieConfiguration = JSON.parse(result?.jsonObject ?? '{}');
          return {
            ...result,
            bowtieConfiguration,
          };
        },
      }),
      getAddonMappings: builder.query<TransformedGetAddonMappingsResultType, GetAddonMappingsApiArg>({
        query: (queryArg) => ({
          url: `/v4/add-ons/mappings?filter=name:eq:bowtie`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
          params: {
            filter: queryArg.filter,
          },
        }),
        transformResponse: (response: GetAddonMappingsApiResponse): TransformedGetAddonMappingsResultType => {
          return response!.result![0];
        },
      }),
      getBackgroundJobDetails: builder.query<
        TransformedGetBackgroundJobDetailsResultType,
        GetBackgroundJobDetailsApiArg
      >({
        query: (queryArg) => ({ url: `/v4/background-jobs/${queryArg.id}` }),
        transformResponse: (response: GetBackgroundJobDetailsApiResponse) => {
          return response.result;
        },
      }),
      getSimpleOptionsForField: builder.query<Array<TransformedFieldOptionDtoObject>, GetSimpleOptionsForFieldApiArg>({
        query: (queryArg) => ({
          url: `/v4/fields/${queryArg.fieldId}/options/simple`,
          params: {
            formId: queryArg.formId,
            hierarchyFieldId: queryArg.hierarchyFieldId,
            dependsOn: queryArg.dependsOn,
            recordId: queryArg.recordId,
            keyValue: queryArg.keyValue,
            limit: queryArg.limit,
            filter: queryArg.filter,
            next: queryArg.next,
          },
        }),
        transformResponse: (response: GetSimpleOptionsForFieldApiResponse): Array<TransformedFieldOptionDtoObject> => {
          return response.items?.map((item) => item as TransformedFieldOptionDtoObject) ?? [];
        },
      }),
      getSimpleRecord: builder.query<TransformedGetSimpleRecordResultType, GetSimpleRecordApiArg>({
        query: (queryArg) => ({
          url: `/v4/records/simple/${queryArg.id}?keyType=id`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        }),
        transformResponse: (response: GetSimpleRecordApiResponse) => {
          return response.result?.results?.[0];
        },
      }),
      getSimpleRecords1: builder.query<TransformedGetSimpleRecords1ResultType, TransformedGetSimpleRecords1ApiArg>({
        query: (queryArg: TransformedGetSimpleRecords1ApiArg) => ({
          url: `/v4/forms/${queryArg.formId}/records/simple?keyType=id${queryArg.filter ? `&filter=${queryArg.filter}` : ''}`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            sort: queryArg.sort,
            sort_directive: queryArg.sortDirective,
            order_by: queryArg.orderBy,
            includeTotal: queryArg.includeTotal,
            fetchLinkedRecords: queryArg.fetchLinkedRecords,
            includeAllFields: queryArg.includeAllFields,
            keyType: queryArg.keyType,
            hierarchyValueType: queryArg.hierarchyValueType,
            captionValuesBase64encoded: queryArg.captionValuesBase64Encoded,
            globalHierarchyMatch: queryArg.globalHierarchyMatch,
          },
        }),
        transformResponse: (response: GetSimpleRecords1ApiResponse): TransformedGetSimpleRecords1ResultType => {
          return response.result?.results ?? [];
        },
      }),
      getSimpleCurrentUser: builder.query<
        TransformedGetSimpleCurrentUserResultType,
        TransformedGetSimpleCurrentUserApiArg
      >({
        query: (queryArg) => ({
          url: `/v4/users/current-user/simple${queryArg.fields ? `?fields=${queryArg.fields}` : ''}`,
        }),
        transformResponse: (response: GetSimpleCurrentUserApiResponse) => {
          return response.result;
        },
      }),
      getSimpleUsers: builder.query<TransformedGetSimpleUsersResultType, TransformedGetSimpleUsersApiArg>({
        query: (queryArg) => ({
          url: `/v4/users/simple`,
          params: {
            page: queryArg.page,
            pageSize: queryArg.pageSize,
            fields: queryArg.fields,
            filter: queryArg.filter,
            sort: queryArg.sort,
            fullName: queryArg.fullName,
            logicalOperator: queryArg.logicalOperator,
          },
        }),
        transformResponse: (
          // The API schema documentation is incorrect, the response is not just a `GetSimpleUsersApiResponse`
          response: GetSimpleUsersApiResponse & {
            page?: number;
            hits?: number;
            total?: number;
            items?: Array<UserRequestDtoObjectRead>;
          }
        ) => {
          return response?.items ?? [];
        },
      }),
      patchSimple: builder.mutation<PatchSimpleApiResponse, TransformedPatchSimpleApiArg>({
        query: (queryArg) => ({
          url: `/v4/records/simple/${queryArg.id}?keyType=id`,
          method: 'PATCH',
          body: queryArg.simpleRecordDto,
          params:
            Object.keys(queryArg.params).length === 0
              ? undefined
              : {
                  params: queryArg.params,
                },
        }),
      }),
      getBowtieConfiguration: builder.query<BowtieConfigDto | undefined, GetBowtieConfigurationApiArg>({
        query: () => ({ url: `/v4/bowtie/configuration` }),
        transformResponse: (response: GetBowtieConfigurationApiResponse) => {
          return response.result;
        },
        providesTags: ['bowtie-configuration'],
      }),
      setBowtieConfiguration: builder.mutation<SetBowtieConfigurationApiResponse, SetBowtieConfigurationApiArg>({
        query: (queryArg) => ({ url: `/v4/bowtie/configuration`, method: 'PUT', body: queryArg.bowtieConfigDto }),
        invalidatesTags: ['bowtie-configuration'],
      }),
      //NOTE: failure on generating this specific endpoint, using this one for now
      formConfiguration: builder.query<DynamicFormSettings, number>({
        query: (formId) => ({
          url: `/v4/forms/simple/${formId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        }),
        transformResponse: (response: DynamicFormSettingsWrapper): DynamicFormSettings => response.result,
      }),
    }),
  });

// Inject the v4 API with a query to fetch the bowtie configuration
export const enhancedV4Api = _enhancedV4Api.injectEndpoints({
  endpoints: (builder) => ({
    appConfiguration: builder.query<BowtieConfiguration, void>({
      async queryFn(arg, api, extraOptions, fetchBaseQuery) {
        // fetch the bowtie addon mapping
        const addonMappingResponse = await fetchBaseQuery({
          url: `/v4/add-ons/mappings?filter=name:eq:bowtie`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        if (addonMappingResponse.error) {
          return { error: addonMappingResponse.error as FetchBaseQueryError };
        }

        const { id: bowtieAddonId, formId: mainFormId } = (addonMappingResponse.data as GetAddonMappingsApiResponse)
          .result![0];

        // fetch the bowtie addon config
        const addonResponse = await fetchBaseQuery({
          url: `/v4/add-ons/${bowtieAddonId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        if (addonResponse.error) {
          return { error: addonResponse.error as FetchBaseQueryError };
        }

        const addonResponseData = addonResponse.data as EntityResponseDtoAddonResultDto;
        const addonResponseDataResult = addonResponseData.result?.result;
        const bowtieConfiguration = JSON.parse(addonResponseDataResult?.jsonObject ?? '{}');

        // main form
        const mainFormPromise = fetchBaseQuery({
          url: `/v4/forms/simple/${mainFormId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        // controls form
        const controlsFormId = bowtieConfiguration.controls.formId;
        const controlsFormPromise = fetchBaseQuery({
          url: `/v4/forms/simple/${controlsFormId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        // causes form
        const causesFormId = bowtieConfiguration.causes.formId;
        const causesFormPromise = fetchBaseQuery({
          url: `/v4/forms/simple/${causesFormId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        // consequences form
        const consequencesFormId = bowtieConfiguration.consequences.formId;
        const consequencesFormPromise = fetchBaseQuery({
          url: `/v4/forms/simple/${consequencesFormId}`,
          validateStatus: (response, result) => response.status === 200 && result?.result,
        });

        try {
          const [mainFormResponse, controlsFormResponse, causesFormResponse, consequencesFormResponse] =
            await Promise.all([mainFormPromise, controlsFormPromise, causesFormPromise, consequencesFormPromise]);

          const mainFormConfig = (mainFormResponse.data as DynamicFormSettingsWrapper).result;
          const controlsFormConfig = (controlsFormResponse.data as DynamicFormSettingsWrapper).result;
          const causesFormConfig = (causesFormResponse.data as DynamicFormSettingsWrapper).result;
          const consequencesFormConfig = (consequencesFormResponse.data as DynamicFormSettingsWrapper).result;

          // return the bowtie configuration
          const config = cloneDeep(bowtieConfiguration);
          config!.main.form = mainFormConfig;
          config!.controls.form = controlsFormConfig;
          config!.causes.form = causesFormConfig;
          config!.consequences.form = consequencesFormConfig;

          return { data: config as BowtieConfiguration };
        } catch (_) {
          return {
            error: {
              data: 'Unable to fetch the form configurations',
              status: 500,
            } as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

export const {
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetAddon1Query: useBowtieAddonQuery,
  useGetAddonMappingsQuery: useBowtieMappingQuery,
  useGetBackgroundJobDetailsQuery,
  useGetSimpleOptionsForFieldQuery,
  useLazyGetSimpleOptionsForFieldQuery,
  useGetSimpleRecordQuery,
  useLazyGetSimpleRecordQuery,
  useGetSimpleRecords1Query: useGetSimpleRecordsQuery,
  useLazyGetSimpleRecords1Query: useLazyGetSimpleRecordsQuery,
  useGetSimpleUsersQuery,
  useLazyGetSimpleUsersQuery,
  useGetSimpleCurrentUserQuery,
  useFormConfigurationQuery,
  usePatchSimpleMutation,
  useAppConfigurationQuery,
  useGetBowtieConfigurationQuery,
  useSetBowtieConfigurationMutation,
} = enhancedV4Api;
