import { useReactFlow } from '@xyflow/react';
import { useCallback, useRef } from 'react';
import { useAppPermissionsContext } from '../../context/app-permissions.context';
import { DiagramNodeType } from '../@types/diagram';
import { isContainerNode } from '../util/node-util';

/**
 * Custom hook for handling diagram interactions in a React Flow diagram.
 *
 * @returns An object containing event handler functions:
 * - `handleNodeClick`: Manages node click events and edit mode states
 * - `handleClick`: Handles diagram background clicks to exit node edit modes
 *
 * @example
 * ```tsx
 * const {
 *   handleNodeClick,
 *   handleClick,
 * } = useDiagramHandlers();
 * ```
 *
 * @remarks
 * This hook maintains a reference to the currently edited node and provides
 * functionality to toggle edit modes on nodes and hover states on edges.
 * Container nodes are excluded from entering edit mode.
 */
const useDiagramHandlers = () => {
  const nodeInEditMode = useRef<string>();
  const { updateNodeData } = useReactFlow();
  const { resolvePermissionsByNodeType } = useAppPermissionsContext();

  // click handler for all nodes
  const handleNodeClick = useCallback(
    (event: React.MouseEvent, clickedNode: DiagramNodeType) => {
      if (resolvePermissionsByNodeType(clickedNode.type).editNodePermission) {
        if (clickedNode.type === undefined || isContainerNode(clickedNode.type)) {
          // do not enter edit mode for container nodes
          return;
        }

        event.preventDefault();
        event.stopPropagation();

        // exit edit mode for the previous node
        if (nodeInEditMode.current) {
          if (nodeInEditMode.current === clickedNode.id) {
            return;
          }

          updateNodeData(nodeInEditMode.current, { editMode: false });
        }

        // enter edit mode for the clicked node
        updateNodeData(clickedNode.id, { editMode: true });
        nodeInEditMode.current = clickedNode.id;
      }
    },
    [resolvePermissionsByNodeType]
  );

  // click handler for the diagram
  const handleClick = useCallback(() => {
    if (nodeInEditMode.current) {
      // exit edit mode
      updateNodeData(nodeInEditMode.current, { editMode: false });
      nodeInEditMode.current = undefined;
    }
  }, [updateNodeData]);

  // keydown event handler for the diagram
  const handleKeyDownEvent = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === 'Escape' || event.code === 'Enter' || event.code === 'NumpadEnter') {
        // exit edit mode for the previous node
        nodeInEditMode.current = undefined;
      }
    },
    [handleClick]
  );

  return {
    handleNodeClick,
    handleClick,
    handleKeyDownEvent,
  };
};

export default useDiagramHandlers;
