import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSaveBowtieMutation, useUpdateBowtieMutation } from '../api/enhanced/enhanced-bowtie-api';
import { BowtieDto } from '../api/generated/bowtie-api';

/**
 * Custom hook for handling the saving and updating of bow-tie diagrams.
 *
 * @param bowtieDto - Optional BowtieDto object containing the diagram data to be saved
 * @param mueLabel - Optional string representing the Material Unwanted Event label
 *
 * @returns An object containing:
 * - loading: boolean indicating if save/update operation is in progress
 * - label: string displaying either "Update" or "Save" based on whether diagram exists
 * - handleClick: async function that handles the save/update operation
 *
 * @remarks
 * - For flow diagrams, both mueLabel and bowtieDto are required
 * - Shows toast notifications for success/error states
 * - Redirects to new URL with diagramId after successful save of new diagram
 * - Uses existing diagramId from URL params for updates
 * - Will show error toast if save/update operation fails
 */
const useSaveDiagram = (bowtieDto?: BowtieDto, mueLabel?: string) => {
  const [loading, setLoading] = useState(false);
  const { search, pathname } = useLocation();
  const isFlowDiagram = !pathname.includes('legacy');

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const diagramId = queryParams.get('diagramId');

  const [saveBowtie] = useSaveBowtieMutation();
  const [updateBowtie] = useUpdateBowtieMutation();

  const handleClick = useCallback(async () => {
    if (isFlowDiagram && (!mueLabel || !bowtieDto)) {
      toast.error('Please specify the Material Unwanted Event', { autoClose: 2000 });
      return;
    }

    setLoading(true);
    const toastId = toast.loading('Saving the diagram...');

    try {
      const result = diagramId
        ? await updateBowtie({ bowtieId: diagramId, bowtieDto: bowtieDto! }).unwrap()
        : await saveBowtie({ bowtieDto: bowtieDto! }).unwrap();

      toast.update(toastId, {
        render: 'Diagram saved successfully',
        type: 'success',
        isLoading: false,
        autoClose: 2000,
      });

      if (!diagramId) {
        const params = new URLSearchParams({ diagramId: result as string });
        setTimeout(
          () => window.location.replace(`${window.location.origin}${window.location.pathname}?${params}`),
          2000
        );
      }
    } catch (_) {
      toast.update(toastId, {
        render: 'Unable to save the diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [bowtieDto, mueLabel]);

  return {
    loading,
    label: diagramId ? 'Update' : 'Save',
    handleClick,
    diagramId,
  };
};

export default useSaveDiagram;
