import { Controls, EdgeChange, NodeChange, ReactFlow, type Edge, type Node } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { onEdgesChange, onNodesChange, selectEdges, selectMueRecordId, selectNodes } from '../redux/slices/diagram';
import MoveNodesControl from './components/controls/move-nodes-control.component';
import useDiagramHandlers from './hooks/use-diagram-handlers';
import { diagramEdgeTypes } from './util/edge-util';
import { diagramNodeTypes } from './util/node-util';

/**
 * Diagram component renders a full-screen diagram using ReactFlow.
 *
 * @returns {JSX.Element} The rendered diagram component.
 *
 * @component
 * @example
 * return (
 *   <Diagram />
 * )
 *
 * @see {@link https://reactflow.dev/|ReactFlow Documentation}
 */
const Diagram = (): JSX.Element => {
  const { handleNodeClick, handleClick, handleKeyDownEvent } = useDiagramHandlers();
  const nodes = useAppSelector(selectNodes);
  const edges = useAppSelector(selectEdges);
  const mueRecordId = useAppSelector(selectMueRecordId);
  const dispatch = useAppDispatch();

  const handleOnNodesChange = useCallback((nodeChanges: NodeChange<Node>[]) => {
    // Delay the dispatch to prevent ResizeObserver loop issue: https://github.com/xyflow/xyflow/issues/3076
    setTimeout(() => dispatch(onNodesChange(nodeChanges)));
  }, []);

  const handleOnEdgesChange = useCallback((edgeChanges: EdgeChange<Edge>[]) => {
    // Delay the dispatch to prevent ResizeObserver loop issue: https://github.com/xyflow/xyflow/issues/3076
    setTimeout(() => dispatch(onEdgesChange(edgeChanges)));
  }, []);

  return (
    <ReactFlow
      className="nowheel"
      nodes={nodes}
      edges={edges}
      nodeTypes={diagramNodeTypes}
      edgeTypes={diagramEdgeTypes}
      onNodesChange={handleOnNodesChange}
      onEdgesChange={handleOnEdgesChange}
      onNodeClick={handleNodeClick}
      onClick={handleClick}
      onKeyDown={handleKeyDownEvent}
      fitView
      panOnDrag
      edgesFocusable={false}
      nodesDraggable={false}
      nodesConnectable={false}
      nodesFocusable={false}
      draggable={false}
      elementsSelectable={false}
      zoomOnDoubleClick={false}
    >
      <Controls showInteractive={false}>{!mueRecordId && <MoveNodesControl />}</Controls>
    </ReactFlow>
  );
};

export default Diagram;
