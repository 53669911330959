import { Edge, EdgeProps, BaseEdge as FlowBaseEdge, useReactFlow, XYPosition } from '@xyflow/react';
import { memo, useMemo } from 'react';
import { useAppPermissionsContext } from '../../../context/app-permissions.context';
import { useAppDispatch } from '../../../redux/hooks';
import { addNode } from '../../../redux/slices/diagram';
import { DIAGRAM_MODE } from '../../../services/bowtie-data-types';
import { EdgeDirection, getPathLineFromNodeHandleToMainPath, resolveMainPathValues } from '../../util/edge-util';
import PlusIcon from './icons/plus-icon.component';

const plusIconSize = 24; //px
const mainPathClassName = 'main-path group-hover:bt-stroke-[3px] group-hover:[stroke-dasharray:8]';

export type BaseEdgeData = {
  containerId: string;
  controls?: Array<string>;
  direction: EdgeDirection;
  diagramMode: DIAGRAM_MODE;
  hovered?: boolean;
  rowIndex?: number; // cause / conseqeunce index
  parentRecordId?: number; // cause / consequence recordId
};
export type BaseEdgeType = Edge<BaseEdgeData>;
export type BaseEdgeProps = EdgeProps<BaseEdgeType>;

/**
 * BaseEdge component renders the edges between nodes in a flow diagram.
 *
 * It calculates the main path and connector paths based on the provided
 * source and target coordinates, as well as additional data such as container
 * and control nodes.
 *
 * @component
 * @param {BaseEdgeProps} props - The properties for the BaseEdge component.
 * @param {number} props.sourceX - The x-coordinate of the source node.
 * @param {number} props.sourceY - The y-coordinate of the source node.
 * @param {number} props.targetX - The x-coordinate of the target node.
 * @param {number} props.targetY - The y-coordinate of the target node.
 * @param {Object} props.data - Additional data for the edge.
 * @param {string} props.data.containerId - The ID of the container node.
 * @param {Array<string>} props.data.controls - The IDs of the control nodes.
 *
 * @returns {JSX.Element} The rendered BaseEdge component.
 */
const BaseEdge = (props: BaseEdgeProps): JSX.Element => {
  const { sourceY, data } = props;
  const { containerId, controls, direction, rowIndex, parentRecordId } = data ?? {};

  const { getInternalNode } = useReactFlow();
  const containerNode = useMemo(() => getInternalNode(containerId!), [containerId]);

  const dispatch = useAppDispatch();

  const {
    addNodePermission: { controls: canAddControl },
  } = useAppPermissionsContext();

  const handleAdd = () => {
    if (!canAddControl || rowIndex === undefined || direction === undefined) return;

    const type = direction === EdgeDirection.LeftToRight ? 'preventative-control-node' : 'mitigating-control-node';
    dispatch(addNode({ type, data: { id: crypto.randomUUID(), parentRecordId }, rowIndex }));
  };

  // main path
  const { mainY, mainPathPart1, mainPathPart2 } = resolveMainPathValues(
    props,
    containerNode!.position,
    containerNode!.measured
  );

  // connect nodes to main path
  const connectorPaths = useMemo(() => {
    // paths from control to 'main' line
    const connectorHandles: Array<XYPosition> = controls
      ?.map((controlId) => {
        const node = getInternalNode(controlId);
        if (node && node.data.filtered !== true) {
          const handle = node?.internals.handleBounds?.target?.[0];

          if (handle) {
            // coordinates of the box connector
            const handleX = node.internals.positionAbsolute.x + handle.x + handle.width / 2;
            const handleY = node.internals.positionAbsolute.y + handle.y + handle.height / 2;

            return { x: handleX, y: handleY };
          }
        }
      })
      .filter((handleXY) => handleXY !== undefined) as XYPosition[];

    return connectorHandles.map(({ x: handleX, y: handleY }) =>
      getPathLineFromNodeHandleToMainPath(handleX, handleY, sourceY)
    );
  }, [controls, sourceY]);

  // edge button position
  const buttonX = containerNode!.position.x + (containerNode!.measured.width ?? 0) / 2 - plusIconSize / 2;
  const buttonY = mainY - plusIconSize / 2;

  return (
    <g className="nopan nodrag bt-group bt-cursor-default">
      <FlowBaseEdge path={mainPathPart1!} className={mainPathClassName} />
      <FlowBaseEdge path={mainPathPart2!} className={mainPathClassName} />
      {connectorPaths?.map((path) => {
        return <FlowBaseEdge key={path} path={path} interactionWidth={0} />;
      })}
      {/* Add Node */}
      {canAddControl && (
        <g
          style={{
            transform: `translate(${buttonX}px,${buttonY}px)`,
          }}
          className="add-node bt-cursor-pointer"
          onClick={handleAdd}
        >
          <title>Add Control</title>
          <PlusIcon
            width={plusIconSize}
            height={plusIconSize}
            className="bt-fill-cline bt-stroke-cline bt-stroke-2 bt-text-mono-1"
          />
        </g>
      )}
    </g>
  );
};

export default memo(BaseEdge);
