import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { BowtieRequestWithId } from '../../api/@types/enhanced-bowtie-api.types';
import { BowtieDto as SaveBowtieDto } from '../../api/generated/bowtie-api';
import { BowtieDto } from '../../api/generated/v4-api';
import MyoshBowtieLogo from '../../assets/svg/myosh-bowtie-logo.svg';
import { useDiagramContext } from '../../context/diagram.context';
import { cn } from '../../helpers/util';
import { useAppSelector } from '../../redux/hooks';
import { JobStatus, selectHasRunningJobs, selectSubmittedJobs } from '../../redux/slices/running-jobs';
import AISplitButton from './ai-split-button.component';
import ControlLegend from './control-legend.component';
import CreateSplitButton from './create-split-button.component';
import FilterOverlay from './filter-overlay.component';
import RecordDropDown from './record-drop-down.component';
import RunningJobsPanel from './running-jobs-panel.component';
import Settings from './settings.component';

interface HeaderProps {
  onAIAnalysisReady: (text: string) => void;
  // optional props are temporary and are used with 'flow' diagrams
  className?: string;
  canCreateBackendRecords?: boolean;
  bowtieDtoCreate?: BowtieDto;
  bowtieDtoAnalyze?: BowtieRequestWithId;
  bowtieDtoSave?: SaveBowtieDto;
  mueLabel?: string;
  mueRecordId?: number;
}

function Header({
  canCreateBackendRecords,
  className,
  bowtieDtoCreate,
  bowtieDtoAnalyze,
  bowtieDtoSave,
  mueLabel,
  mueRecordId,
  onAIAnalysisReady,
}: HeaderProps) {
  const hasRunningJobs = useAppSelector(selectHasRunningJobs);
  const submittedJobs = useAppSelector(selectSubmittedJobs);
  const latestJobStatus = submittedJobs[0]?.status;

  const { pathname } = useLocation();
  const isFlowDiagram = !pathname.includes('legacy');

  const { isAIGenerated, isSavedDiagram } = useDiagramContext();

  return (
    <div
      className={cn(
        'bt-sticky bt-left-0 bt-top-0 bt-z-[1300] bt-mt-2 bt-flex bt-h-12 bt-justify-between bt-bg-mono-1 bt-px-2',
        className
      )}
      id="header"
    >
      <div className="bt-flex bt-flex-row bt-gap-3">
        <img src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
        {!isAIGenerated && (
          <>
            <RecordDropDown />
            <AISplitButton onAIAnalysisReady={onAIAnalysisReady} bowtieDtoAnalyze={bowtieDtoAnalyze} />
          </>
        )}
        {(isAIGenerated || isSavedDiagram || (isFlowDiagram && canCreateBackendRecords)) && (
          <div
            className={cn('bt-flex bt-flex-row bt-gap-2', {
              'bt-hidden': hasRunningJobs || latestJobStatus === JobStatus.Success,
            })}
          >
            <CreateSplitButton
              bowtieDtoCreate={bowtieDtoCreate}
              bowtieDtoSave={bowtieDtoSave}
              mueLabel={mueLabel}
              mueRecordId={mueRecordId}
            />
          </div>
        )}
        <RunningJobsPanel submittedJobs={submittedJobs} />
        <FilterOverlay />
      </div>
      <div className="bt-flex bt-min-w-[435px]">
        <ControlLegend />
        <Settings />
      </div>
    </div>
  );
}

export default memo(Header);
