import { compact, uniq } from 'lodash';
import { DynamicFormSettings } from '../@types/form-data-types';
import { RecordResult, RecordsWrapper } from './record-data-types';
import { ServiceBase } from './service-base';

export class RecordService extends ServiceBase {
  constructor(env: string) {
    super(env);
  }

  public addControl(
    controlId: string,
    controlValue: string,
    controlFormId: number,
    parentControlId: number,
    controlCaptionField: string,
    controlsForm: DynamicFormSettings,
    isPreventative: boolean
  ) {
    const dynamicFields: Record<string, unknown> = isPreventative
      ? {
          'Preventative/Mitigating': {
            value: 'Preventative',
          },
          Causes: [String(parentControlId)],
        }
      : {
          'Preventative/Mitigating': {
            value: 'Mitigating',
          },
          Consequences: [String(parentControlId)],
        };
    const workflowSteps = controlsForm.workflowSteps;

    const data: Array<RecordResult> = [
      {
        sourceId: controlId,
        formId: controlFormId,
        hierarchies: {},
        fields: {
          [controlCaptionField]: controlValue,
          ...dynamicFields,
        },
        status: workflowSteps ? workflowSteps.find((step) => step.draft === true)?.label || 'Draft' : 'Draft',
      },
    ];

    const requestUrl = this.createEndpointUrl('records/simple?keyType=id');
    return this.performApiRequest<RecordsWrapper, Array<RecordResult>>(requestUrl, 'POST', data);
  }

  public updateControl(
    controlId: string,
    controlValue: string,
    controlFormId: number,
    parentControlId: number,
    controlCaptionField: string,
    existingControl: RecordResult,
    isPreventative: boolean
  ) {
    const dynamicFields: Record<string, unknown> = isPreventative
      ? {
          Causes: uniq([...((existingControl.fields['Causes'] as Array<unknown>) || []), String(parentControlId)]),
        }
      : {
          Consequences: uniq([
            ...((existingControl.fields['Consequences'] as Array<unknown>) || []),
            String(parentControlId),
          ]),
        };

    const data: Partial<RecordResult> = {
      id: Number(controlId),
      formId: controlFormId,
      hierarchies: {},
      fields: {
        [controlCaptionField]: controlValue,
        ...dynamicFields,
      },
      status: existingControl.status,
    };

    const requestUrl = this.createEndpointUrl(`records/simple/${controlId}?keyType=id`);
    return this.performApiRequest<RecordsWrapper, Partial<RecordResult>>(requestUrl, 'PUT', data);
  }

  public unlinkControl(
    controlId: string,
    controlFormId: number,
    controlModuleId: number,
    parentControlId: number,
    existingControl: RecordResult,
    isPreventative: boolean
  ) {
    const controlLinks = (
      isPreventative ? existingControl.fields['Causes'] : existingControl.fields['Consequences']
    ) as Array<string>;
    const updatedFields: Record<string, unknown> = isPreventative
      ? {
          Causes: controlLinks.filter((link) => link !== String(parentControlId)),
        }
      : {
          Consequences: controlLinks.filter((link) => link !== String(parentControlId)),
        };

    const data: Partial<RecordResult> = {
      id: Number(controlId),
      formId: controlFormId,
      moduleId: controlModuleId,
      hierarchies: {},
      fields: {
        ...updatedFields,
      },
      status: existingControl.status,
    };

    const requestUrl = this.createEndpointUrl(`records/simple/${controlId}?keyType=id`);
    return this.performApiRequest<RecordsWrapper, Partial<RecordResult>>(requestUrl, 'PATCH', data);
  }

  public addCauseConsequence(
    value: string,
    formId: number,
    captionField: string,
    form: DynamicFormSettings,
    riskScenarioId: number
  ) {
    const requestUrl = this.createEndpointUrl('records/simple?keyType=id');

    const workflowSteps = form.workflowSteps;

    const data: Array<RecordResult> = [
      {
        formId,
        hierarchies: {},
        fields: {
          [captionField]: value,
          Risk: [String(riskScenarioId)],
        },
        status: workflowSteps
          ? (workflowSteps.find((step) => step.draft === true)?.label ?? workflowSteps[0].label)
          : '',
      },
    ];

    return this.performApiRequest<RecordsWrapper, Array<RecordResult>>(requestUrl, 'POST', data);
  }

  public fetchRecord(recordId: number) {
    const requestUrl = this.createEndpointUrl(`records/simple/${recordId}?keyType=id`);
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public fetchRecords(formId: number, recordIds: Array<number>) {
    const requestUrl = this.createEndpointUrl(`forms/${formId}/records/simple`, {
      filter: `id:in:${compact(recordIds).join(',')}`,
      keyType: 'id',
      // workaround to specify a large enough page size to fix issue with missing controls
      pageSize: 200,
    });
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }

  public fetchFilteredRecordsByFormId(formId: number, filter: string) {
    const requestUrl = this.createEndpointUrl(`forms/${formId}/records/simple`, {
      filter,
      keyType: 'id',
    });
    return this.performApiRequest<RecordsWrapper>(requestUrl, 'GET');
  }
}
