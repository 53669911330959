import { useCallback, useMemo, useRef } from 'react';
import { BowtieRequestWithId } from '../api/@types/enhanced-bowtie-api.types';
import { ControlDto, BowtieDto as SaveBowtieDto } from '../api/generated/bowtie-api';
import { BowtieDto } from '../api/generated/v4-api';
import AiAnalysisSuggestionContainer, {
  AIAnalysisSuggestionContainerRef,
} from '../components/common/ai-analysis-suggestion-container';
import MainHeader from '../components/header/header.component';
import { useAppSelector } from '../redux/hooks';
import {
  selectCauses,
  selectConsequences,
  selectDiagramDisabled,
  selectHazard,
  selectMueLabel,
  selectMueRecordId,
} from '../redux/slices/diagram';
import { CauseDiagramNode, ConsequenceDiagramNode, ControlDiagramNode, DiagramNode } from './@types/diagram';
import useControlValues, { ValueResolvers } from './hooks/use-control-value-resolvers';
import {
  criticalFilterValues,
  initialImplementationRequiredValues,
  materialRiskRelationValues,
} from './util/constants';
import { CriticalControlEnum } from './util/node-util';

// The header component used in the flow diagram
const Header = () => {
  const { valueResolvers, isFetching: isFetchingResolvers } = useControlValues();

  // NOTE: should be simplified once we take flow diagrams into use
  const mueRecordId = useAppSelector(selectMueRecordId);
  const mueLabel = useAppSelector(selectMueLabel);
  const hazard = useAppSelector(selectHazard);
  const causes = useAppSelector(selectCauses);
  const consequences = useAppSelector(selectConsequences);
  const disableDiagram = useAppSelector(selectDiagramDisabled);

  const analysisRef = useRef<AIAnalysisSuggestionContainerRef>(null);
  const onAIAnalysisReady = useCallback((text: string) => {
    analysisRef.current?.show(text);
  }, []);

  const bowtieDtoCreate = useMemo(
    () =>
      hazard && !isFetchingResolvers
        ? buildCreateBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>,
            valueResolvers
          )
        : undefined,
    [mueLabel, hazard, causes, consequences, isFetchingResolvers]
  );

  const bowtieDtoAnalyze = useMemo(
    () =>
      hazard
        ? buildAnalyzeBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>
          )
        : undefined,
    [mueLabel, hazard, causes, consequences]
  );

  const bowtieDtoSave = useMemo(
    () =>
      hazard
        ? buildSaveBowtieDto(
            mueLabel,
            hazard,
            causes as Array<CauseDiagramNode>,
            consequences as Array<ConsequenceDiagramNode>
          )
        : undefined,
    [mueLabel, hazard, causes, consequences]
  );

  return (
    <div className="bt-relative bt-flex bt-w-full bt-flex-col">
      {disableDiagram && <div className="bt-absolute bt-z-[1301] bt-h-full bt-w-full bt-bg-transparent" />}
      <MainHeader
        onAIAnalysisReady={onAIAnalysisReady}
        className="bt-mt-0 bt-h-14 bt-pt-2"
        canCreateBackendRecords={!mueRecordId}
        bowtieDtoCreate={bowtieDtoCreate}
        bowtieDtoAnalyze={bowtieDtoAnalyze}
        bowtieDtoSave={bowtieDtoSave}
        mueLabel={mueLabel}
        mueRecordId={mueRecordId}
      />
      <AiAnalysisSuggestionContainer ref={analysisRef} />
    </div>
  );
};

export default Header;

/* Temporary utilities to ensure the header works with flow diagrams */

// Builds the bowtieDto object used for 'creating' the backend records
const buildCreateBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>,
  valueResolvers: ValueResolvers
) => {
  return {
    hazard: hazard.label,
    riskScenario: mueLabel,
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        cause: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => _buildCreateBowtieDtoControl(control, valueResolvers)),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        consequence: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => _buildCreateBowtieDtoControl(control, valueResolvers)),
      })),
  } as BowtieDto;
};

const _buildCreateBowtieDtoControl = (control: ControlDiagramNode, valueResolvers: ValueResolvers) => {
  const {
    criticalRankIdResolver,
    categoryIdResolver,
    controlTypeResolver,
    initialImplementationRequiredIdResolver,
    materialRiskRelationIdResolver,
  } = valueResolvers;

  let _criticalRankId, _categoryId, _typeId, _initialImplementationRequiredId, _materialRiskRelationFieldId;

  if (control._metadata?.aiData) {
    const { isCritical, category, controlType, isInitial, isControlIssues } = control._metadata.aiData;

    if (isCritical) {
      _criticalRankId = criticalRankIdResolver(criticalFilterValues.truthy);
    } else {
      _criticalRankId = criticalRankIdResolver(criticalFilterValues.falsy);
    }

    _categoryId = categoryIdResolver(category);

    _typeId = controlTypeResolver(controlType);

    if (isInitial) {
      _initialImplementationRequiredId = initialImplementationRequiredIdResolver(
        initialImplementationRequiredValues.truthy
      );
    } else {
      _initialImplementationRequiredId = initialImplementationRequiredIdResolver(
        initialImplementationRequiredValues.falsy
      );
    }

    if (isControlIssues) {
      _materialRiskRelationFieldId = materialRiskRelationIdResolver(materialRiskRelationValues.truthy);
    } else {
      _materialRiskRelationFieldId = materialRiskRelationIdResolver(materialRiskRelationValues.falsy);
    }
  }

  return {
    name: control.label,
    isGlobal: control.global,
    criticalRankId: _criticalRankId,
    categoryId: _categoryId,
    typeId: _typeId,
    initialImplementationRequiredId: _initialImplementationRequiredId,
    materialRiskRelationId: _materialRiskRelationFieldId,
  };
};

// Builds the bowtieDto object used for 'analyzing' the diagram
const buildAnalyzeBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    term: '',
    hazard: hazard.label ?? '',
    risk: mueLabel ?? '',
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        id: cause.id,
        name: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            type: 'Preventative',
            // FIXME: These should be resolved from the underlying record data
            isInitial: false,
            category: 'Soft',
          })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        id: consequence.id,
        name: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => ({
            id: control.id,
            name: control.label,
            isGlobal: control.global,
            isCritical: control.criticalControlType === CriticalControlEnum.CRITICAL,
            type: 'Mitigating',
            // FIXME: These should be resolved from the underlying record data
            isInitial: false,
            category: 'Soft',
          })),
      })),
  } as BowtieRequestWithId;
};

// Builds the bowtieDto object used for 'saving' the diagram
const buildSaveBowtieDto = (
  mueLabel: string | undefined,
  hazard: DiagramNode,
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  return {
    hazard: hazard.label ?? '',
    risk: mueLabel ?? '',
    causes: causes
      .filter((cause) => cause.label)
      .map((cause) => ({
        name: cause.label,
        controls: cause.controls
          .filter((control) => control.label)
          .map((control) => ({
            ..._buildSaveBowtieDtoControl(control),
            type: 'Preventative',
          })),
      })),
    consequences: consequences
      .filter((consequence) => consequence.label)
      .map((consequence) => ({
        name: consequence.label,
        controls: consequence.controls
          .filter((control) => control.label)
          .map((control) => ({
            ..._buildSaveBowtieDtoControl(control),
            type: 'Mitigating',
          })),
      })),
  } as SaveBowtieDto;
};

const _buildSaveBowtieDtoControl = (control: ControlDiagramNode) => {
  let _control: ControlDto = { name: control.label, isGlobal: control.global };

  if (control._metadata?.aiData) {
    const { isCritical, category, controlType, isInitial } = control._metadata.aiData;

    _control = {
      ..._control,
      isCritical,
      isInitial,
      controlType,
      category,
    };
  }

  return _control;
};
