import { Loader } from '@myosh/odin-components';
import React, { createContext, useContext, useRef } from 'react';
import { useGetBowtieConfigurationQuery, useGetSimpleCurrentUserQuery } from '../api/enhanced/enhanced-v4-api';
import { BowtieConfiguration, FormNameKey } from '../services/common-data-types';
import { useConfigurationContext } from './configuration.context';

interface UserPermissions {
  createRecord: Record<FormNameKey, boolean>;
  editRecord: Record<FormNameKey, boolean>;
  deleteRecord: Record<FormNameKey, boolean>;
  isSuperUser: boolean;
  // FIXME:  'aiEnabled' should be moved out of the user permissions context
  aiEnabled: boolean;
}

const noUserPermissionsValue = { main: false, causes: false, consequences: false, controls: false };

const initialPermissions: UserPermissions = {
  createRecord: noUserPermissionsValue,
  editRecord: noUserPermissionsValue,
  deleteRecord: noUserPermissionsValue,
  isSuperUser: false,
  aiEnabled: false,
};

const UserPermissionsContext = createContext<UserPermissions | undefined>(undefined);

interface UserPermissionsProviderProps {
  children: React.ReactNode;
}

export const UserPermissionsProvider: React.FC<UserPermissionsProviderProps> = ({
  children,
}: UserPermissionsProviderProps) => {
  const { data: currentUser, isLoading } = useGetSimpleCurrentUserQuery({ fields: 'groups' });

  const bowtieConfiguration = useConfigurationContext();
  const permissionsRef = useRef<UserPermissions>(initialPermissions);

  const { data: bowtieConfig, isFetching: isConfigFetching } = useGetBowtieConfigurationQuery();

  if (!isLoading && currentUser) {
    // Check if the current user is a "super user" in this schema
    // This needs to be done explicitly based on the clarification: https://myosh.atlassian.net/browse/MYOSH-9193?focusedCommentId=66919
    const isSuperUser = Boolean(currentUser.id) === false;
    permissionsRef.current.isSuperUser = isSuperUser;

    if (!isSuperUser) {
      const userRoles: Array<string> =
        currentUser.groups?.map((group) => group.caption as unknown as string).filter((role) => role !== undefined) ??
        [];

      if (userRoles.length > 0) {
        // create record permissions
        Object.keys(bowtieConfiguration).forEach((key) => {
          const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;

          if (form.createRecordRoles && form.createRecordRoles.length > 0) {
            const formCreateRoles = form.createRecordRoles.map((role) => role.caption);

            const userHasRole = formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

            permissionsRef.current!.createRecord = {
              ...permissionsRef.current!.createRecord,
              [key]: userHasRole,
            };
          } else {
            permissionsRef.current!.createRecord = {
              ...permissionsRef.current!.createRecord,
              [key]: false,
            };
          }
        });

        // edit record permissions
        Object.keys(bowtieConfiguration).forEach((key) => {
          const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;
          if (form.editRecordRoles && form.editRecordRoles.length > 0) {
            const formCreateRoles = form.editRecordRoles.map((role) => role.caption);

            const userHasRole = formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

            permissionsRef.current!.editRecord = {
              ...permissionsRef.current!.editRecord,
              [key]: userHasRole,
            };
          } else {
            permissionsRef.current!.editRecord = {
              ...permissionsRef.current!.editRecord,
              [key]: false,
            };
          }
        });

        // delete record permissions
        Object.keys(bowtieConfiguration).forEach((key) => {
          const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;
          if (form.deleteRecordRoles && form.deleteRecordRoles.length > 0) {
            const formCreateRoles = form.deleteRecordRoles.map((role) => role.caption);

            const userHasRole = formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

            permissionsRef.current!.deleteRecord = {
              ...permissionsRef.current!.deleteRecord,
              [key]: userHasRole,
            };
          } else {
            permissionsRef.current!.deleteRecord = {
              ...permissionsRef.current!.deleteRecord,
              [key]: false,
            };
          }
        });
      }
    }
  }

  if (!isConfigFetching && bowtieConfig) {
    permissionsRef.current.aiEnabled = Boolean(bowtieConfig?.aiEnabled);
  }

  return isLoading || isConfigFetching ? (
    <div className="bt-mx-auto bt-flex bt-h-screen bt-w-full bt-flex-col bt-items-center bt-justify-center">
      <Loader title="Loading permissions" />
    </div>
  ) : (
    <UserPermissionsContext.Provider value={permissionsRef.current}>{children}</UserPermissionsContext.Provider>
  );
};

// context consumer hook
export const useUserPermissionsContext = () => {
  const context = useContext(UserPermissionsContext);

  if (context === undefined) {
    throw new Error('useUserPermissionsContext must be used within a UserPermissionsProvider');
  }

  return context;
};
